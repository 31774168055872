/* eslint-disable import/no-anonymous-default-export */
export default {
   colors: {
      text: "#eee",
      backgroundHome: "#2B9C1A",
      backgroundFinal: "#2B9C1A",
      backgroundStep: "#2e632e",
      backgroundContainerStep: "##EAFCFC",
      borderStep: "#52AD42",
      primaryBackgroundColorButton: "#FE0454",
      secundaryBackgroundColorButton: "#FF9600",
      thirthBackgroundColorButton: "#fff",

      primary: "#18443d",
      green: "#00FCAC",
      blue: {
         primary: "#008abd",
         secundary: "#00B6FB",
         third: "#EAFCFC",
         fourth: "#2DC4FA",
      },
      violet: '##4731C7',
      orange: '#FF9703',
      pink: '#FF0353',
      white: "#FFFFFF",
      black: '#000',
      gray1: '#212121',
      gray2: '#424242',
      gray3: '#616161',
      gray4: '#757575',
      gray5: '#9E9E9E',
      gray6: '#BDBDBD',
      gray7: '#E0E0E0',
      gray8: '#EEEEEE',
      gray9: '#F5F5F5',
      shadow: '#bdbdbd',
      greens: {
         primary: '#2B9C1A',
         secundary: '#52AD42',
         third: '#A0D99F',
         fourth: '#3B6526'
      }
   },
   matrics: {
      padding: 25,
      title: 124,
      isoladText: 50,
      renningText: 40,
      borderRadius: 10,
      sm: 640,
      md: 748,
      lg: 1024,
      xl: 1280,
      xl2: 1536,  
   },
}