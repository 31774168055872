import styled, {css} from "styled-components";
import BackgroundStep from "../assets/img/PF01.png";
import theme from "../config/theme/light";
interface ContainerProps {
  backgroundColor?: string;
}

export interface IResponsibleContainer {
  spacingY: boolean;
  spacingX: boolean;
}

interface IFormResponsible {
  spacingY?: boolean;
  spacingX?: boolean;
  verticalGap?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

interface ImageProps {
  src?: string;
}

export interface TextColorProp {
  textColor?: string
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  /* max-height: -webkit-fill-available; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 22% 6fr;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.backgroundContainerStep};
  overflow: auto;
`;

const ResponsibleContainer = styled.div<IResponsibleContainer>`
  margin: 0 auto;
  padding-top: ${({ theme, spacingY = true }) =>
    spacingY && theme.matrics.padding}px;
  padding-bottom: ${({ theme, spacingY = true }) =>
    spacingY && theme.matrics.padding}px;
  padding-left: ${({ theme, spacingX = true }) =>
    spacingX && theme.matrics.padding}px;
  padding-right: ${({ theme, spacingX = true }) =>
    spacingX && theme.matrics.padding}px;
  flex-wrap: nowrap;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    width: 80%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.md}px) {
    width: 60%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.lg}px) {
    width: 55%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.xl}px) {
    width: 50%;
  }
`;

const FormResponsible = styled.div<IFormResponsible>`
  display: flex;
  justify-content: center;
  align-content: space-between;
  height: 100%;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: ${({ theme, spacingX = true }) =>
    spacingX && theme.matrics.padding * 2}px;
  padding-right: ${({ theme, spacingX = true }) =>
    spacingX && theme.matrics.padding * 2}px;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: ${({ sm }) => (sm ? sm : 70)}%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.md}px) {
    width: ${({ md }) => (md ? md : 50)}%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.lg}px) {
    width: ${({ lg }) => (lg ? lg : 35)}%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.xl}px) {
    width: ${({ xl }) => (xl ? xl : 30)}%;
  }
`;

const StepHeader = styled.div`
  position: relative;
  min-height: 100px;
  /* width: 80%; */
  display: flex;
  gap: 10px;
  color: ${({ theme }) => theme.colors.black};
  justify-content: space-between;

  & > div:nth-child(1) {
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 80%;
  }

  @media (min-width: ${({ theme }) => theme.matrics.md}px) {
    align-items: center;
  }
`;

const ImageContainer = styled.div<ImageProps>`
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(${({ src }) => (src ? src : BackgroundStep)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center
`;

ImageContainer.defaultProps = {
  theme: theme
}

const StepForm = styled.div`
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.backgroundStep};
  border-top: 5px solid ${({ theme }) => theme.colors.borderStep};
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  text-align: center;
  margin: auto auto 0.5rem auto;
  text-align: center;

  span {
    line-height: 20px;
    font-size: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    span {
      font-size: 1.1rem;
    }
  }
`;

const HandlerSwitchContainer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  min-height: 80vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
  text-align: center;
  align-content: space-around;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.matrics.padding * 1.25}px;
`;

export const Header = styled.header`
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  font-weight: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    font-weight: 2.2rem;
  }
`;

export const SubTitle = styled.h3<TextColorProp>`
  font-size: 1.1rem;
  color: ${({ theme, textColor }) => textColor ? textColor : theme.colors.gray8};
  text-transform: uppercase;
`;

export const Image = styled.img`
  max-width: 200px;
  margin: auto;
`;

Image.defaultProps = {
  theme: theme
}

export {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  ResponsibleContainer,
  StepForm,
  LabelContainer,
  HandlerSwitchContainer,
  ButtonContainer,
};
