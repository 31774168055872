import {
  BackButton,
  StepTitle,
  Button,
  FieldWithLabelAndIcon,
  SwitchField,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";

import Image from "../../../../../assets/img/PJ12.png";
import Icon from "../../../../../assets/icons/PJ12.png";
import COChart from "../../COChart";

const AirConditioningStep = () => {
  const {
    changeStepScreen,
    setAirCondicionaireSplitQuantity,
    setRefillSplitAirCondicionaire,
    setAirCondicionaireCentralQuantity,
    setRefillCentralAirCondicionaire,
  } = useCompanyContext();

  const formResponsibleProps = {
    spacingX: true,
    spacingY: false,
    verticalGap: 0,
    sm: 80,
    md: 70,
    lg: 65,
    xl: 50,
  };

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container backgroundColor="#fff">
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>SOBRE APARELHOS DE AR CONDICIONADO</StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible {...formResponsibleProps}>


          <div className="w-full flex flex-col md:flex-row md:gap-20 items-end mb-7">
            <div className="w-full">
              <FieldWithLabelAndIcon
                icon={Icon}
                label="Qual a quantidade de aparelhos tipo split que são utilizados?"
                inputType="inputNumber"
                placeholder="0"
                onChange={(value: number | number) =>
                  setAirCondicionaireSplitQuantity(value)
                }
              />
              <div className="w-full md:w-10/12 mt-1 m-auto">
                <SwitchField
                  label="As recargas são regulares?"
                  onChange={(checked: boolean) =>
                    setRefillSplitAirCondicionaire(checked ? 3 : 1)
                  }
                />
              </div>
            </div>

            <div className="w-full">
              <FieldWithLabelAndIcon
                label="Qual a quantidade de ar condicionado central que são utilizados?"
                inputType="inputNumber"
                placeholder="0"
                onChange={(value: number | number) =>
                  setAirCondicionaireCentralQuantity(value)
                }
              />

              <div className="w-full md:w-10/12 mt-1">
                <SwitchField
                  label="As recargas são regulares?"
                  onChange={(checked: boolean) =>
                    setRefillCentralAirCondicionaire(checked ? 3 : 1)
                  }
                />
              </div>
            </div>
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default AirConditioningStep;
