import { useContext } from "react";
import { Percentage, Text } from "./styles";
import { ThemeContext } from "styled-components";

interface InformationProp {
  percentage: number;
  description: string;
  source?: string;
}

const Information = ({ percentage, description, source }: InformationProp) => {
  const themeContext = useContext(ThemeContext);
  const textColor = themeContext.colors.gray4

  return (
    <div className="w-full flex">
      <Percentage>
        <span className="mt-5">{percentage}%</span>
      </Percentage>
      <Text {...{textColor}}>
        <p>{description}</p>
        {source && (
          <div>
            <span>{source}</span>
          </div>
        )}
      </Text>
    </div>
  );
};
export default Information;
