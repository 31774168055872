import styled from "styled-components";
import Background from "../../assets/img/00.png";
import Background2 from "../../assets/img/002.png";

export const GradientContainer = styled.div`
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.blue.secundary},
    ${({ theme }) => theme.colors.blue.secundary},
    ${({ theme }) => theme.colors.white}
  );
`;

export const Content = styled.div`
  width: 100%;
  @media (max-width: 640px) {
    padding: ${({ theme }) => theme.matrics.padding}px;
  }
  @media (min-width: ${({ theme }) => theme.matrics.md}px) {
    width: 50%;
  }
`;
export const SubTitle = styled.h2`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.gray8};

  @media (max-width: 640px) {
    font-size: 1.1rem;
  }
`;
export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: 600;
  color: white;

  @media (max-width: 640px) {
    font-size: 1.8rem;
  }
`;

export const TextContainer = styled.div`
  margin: ${({ theme }) => theme.matrics.padding}px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.white};
  line-height: 0.99;
  font-size: 1rem;
  margin-bottom: 15px;
`;

export const Navbar = styled.div`
  position: absolute;
  top: ${({theme}) => theme.matrics.padding}px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
