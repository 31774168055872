import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    letter-spacing: 0.9;
  }

  body {
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    color: ${({ theme }) => theme.colors.white};

    @media (min-width: ${({ theme }) => theme.matrics.xl2}px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.text};

    @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
      font-size: 1rem;
    }
  }

  h1, h2, h3, h4, h5 {margin: 0; padding: 0}

  input {
    background-color: transparent; color: ${({ theme }) => theme.colors.white};
    color: #f5f5f5;
    width: 100%;
    padding: 5px 10%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray7};
    text-align: center;
    font-weight: 600;

    ::placeholder {
      color: ${({ theme }) => theme.colors.gray7};
    }

    &:focus {
      box-shadow: none;
      border: none;
      box-shadow: 0 0 0 0;
      outline: 0;
      border-bottom: 2px solid ${({ theme }) => theme.colors.white};

    }
  }

  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    
  }
  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;

  }

  .heavy {
    font: bold 16px sans-serif;
  }
`;
