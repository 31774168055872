import { useState } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  Counter,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import Image from "../../../../../assets/img/PJ03.png";
import { Note } from "./styles";

const YearStep = () => {
  const { lastYear, changeStepScreen } = useCompanyContext();

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>O CÁLCULO SE REFERE AO ANO DE:</StepTitle>
          </div>
          <div></div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>

          <div className="w-full flex flex-col justify-center">
            <div className="w-full text-center text-3xl font-bold mb-3">
              {lastYear}
            </div>

            <Note>
              As informações se referem às atividades administrativas
              (escritório), consumo de energia, geração de resíduos, queima de
              combustíveis em equipamentos e para atividades de transporte e
              mobilidade.
            </Note>
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default YearStep;
