import styled, { css } from "styled-components";
import { IResponsibleContainer } from "../../../components/styles";

export const ResponsibleContainer = styled.div<IResponsibleContainer>`
  margin: auto;
  padding-top: ${({ theme, spacingY = true }) =>
    spacingY && theme.matrics.padding}px;
  padding-bottom: ${({ theme, spacingY = true }) =>
    spacingY && theme.matrics.padding}px;
  padding-left: ${({ theme, spacingX = true }) =>
    spacingX && theme.matrics.padding}px;
  padding-right: ${({ theme, spacingX = true }) =>
    spacingX && theme.matrics.padding}px;
  flex-wrap: nowrap;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    width: 70%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.md}px) {
    width: 50%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.lg}px) {
    width: 40%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.xl}px) {
    width: 35%;
  }
`;

interface ContainerProps {
  backgroundColor?: string;
  backgroundImage?: boolean;
  textColor?: string;
}

export const Container = styled.div<ContainerProps>`
  margin-top: 0px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.greens.primary};

  ${(props) =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    background-size: 600px;
    background-position: center 135%;
  }
`;
