import { FormContent } from "./components"

import { PersonProvider } from "./context/personContext"

const PersonCalculator = () => {
  return (
    <PersonProvider>
      <FormContent />
    </PersonProvider>
  )
}
export default PersonCalculator