import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  FieldWithLabelAndIcon,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";

import Image from "../../../../../assets/img/PJ14.png";
import Icon from "../../../../../assets/icons/PJ14.png";
import { CustomLabelCounter } from "../../../../../components/FieldWithLabelAndIcon/styles";
import COChart from "../../COChart";

const RenewebleSourceEnergyStep = () => {
  const {
    lastYear,
    changeStepScreen,
    kwhSourceRenovable,
    setKwhSourceRenovable,
  } = useCompanyContext();
  const [disabled, setDisabled] = useState(false);

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  useEffect(() => {
    kwhSourceRenovable > 0 ? setDisabled(false) : setDisabled(true);
  }, [kwhSourceRenovable]);

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>GERAÇÃO DE ENERGIA RENOVÁVEL</StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <div className="w-full"> </div>

          <FieldWithLabelAndIcon
            icon={Icon}
            label={`Quantos Kwh foram gerados no ano de ${lastYear} a partir dessas fontes?`}
            inputType="inputNumber"
            placeholder="0"
            suffix={<CustomLabelCounter>kWh</CustomLabelCounter>}
            inputIconAlign="right"
            onChange={(value: number) => setKwhSourceRenovable(value)}
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()} disabled={disabled}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default RenewebleSourceEnergyStep;
