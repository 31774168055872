import React from 'react';
import Navigation from './config/navigation';
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as ThemeProviderMUI } from '@mui/material';
import GlobalStyle from './assets/styles/global';
import "./assets/styles/input.css";

import light from './config/theme/light';
import themeMUI from "./config/theme/lightMUI"

function App() {
  return (
    <ThemeProvider theme={light}>
      <ThemeProviderMUI theme={themeMUI}>
        <GlobalStyle />
        <Navigation />
      </ThemeProviderMUI>
    </ThemeProvider>
  );
}

export default App;
