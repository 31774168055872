import * as React from "react";
import InputNumber from "../InputNumber";
import InputText from "../InputText";
import SliderWithCounter, { ISliderWithCounter } from "../SliderWithCounter";
import { Image, Label, CustomLabelCounter } from "./styles";
import DefaultIcon from "../../assets/icons/PJ07.png";

interface FieldWithLabelAndIconProps extends ISliderWithCounter {
  icon?: string;
  label?: string;
  inputType?: "inputText" | "inputNumber" | "slider";
  mask?: "currency" | "meter"
  isDigitable?: boolean;
  customLabel?: React.ReactNode;

  placeholder?: string;
  suffix?: React.ReactNode | string;
  inputIconAlign?: "left" | "right";

  showCounter?: boolean;
  onChange?(value: number | number[]): void;
}

const FieldWithLabelAndIcon = ({
  icon,
  label,
  inputType,
  mask,
  showCounter = true,
  onChange,

  placeholder,
  suffix,
  inputIconAlign,

  customLabel,
  min,
  max, 
}: FieldWithLabelAndIconProps) => {
  const handleChange = (value: number | number[]) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="w-full">
      {icon && (
        <div className="flex w-full justify-center">
          <Image src={icon ? icon : DefaultIcon} />
        </div>
      )}
      {label && <Label>{label}</Label>}

      {inputType === "inputText" ? (
        <div className="w-full">
          <InputText
            fontSize="big"
            {...{ placeholder, suffix, inputIconAlign }}
          />
        </div>
      ) : inputType === "inputNumber" ? (
        <InputNumber
          onChange={(param: number | number[]) => handleChange(param)}
          fontSize="big"
          {...{ placeholder, suffix, inputIconAlign, min, max, mask }}
        />
      ) : (
        <div className="w-full">
          <SliderWithCounter
            onChange={(newValue: number | number[]) => handleChange(newValue)}
            counterLabel={
              <CustomLabelCounter>{customLabel}</CustomLabelCounter>
            }
            {...{ showCounter, min, max }}
          />
        </div>
      )}
    </div>
  );
};

export default FieldWithLabelAndIcon;
