import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  FieldWithLabelAndIcon,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import Image from "../../../../../assets/img/PJ05.png";
import COChart from "../../COChart";

const EmployeeInformationStep = () => {
  const { changeStepScreen } = useCompanyContext();
  const [employeeQuantity, setEmploeeQuantity] = useState<number | number[]>(0)
  const [outSideOffice, setOutSideOffice] = useState<number | number[]>(0);
  const [disabled, setDisabled] = useState(true)

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  useEffect(() => {
    if (employeeQuantity > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [employeeQuantity]);

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>SOBRE OS FUNCIONÁRIOS</StepTitle>
          </div>
          <div>
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <div className="w-full">
            <FieldWithLabelAndIcon
              label="Qual a quantidade de funcionários?"
              inputType="inputNumber"
              placeholder="0"
              onChange={(value:number|number[]) => setEmploeeQuantity(value)}
            />
          </div>

          <div className="w-full">
            <FieldWithLabelAndIcon
              label="Qual a porcentagem de tempo durante a semana, que sua equipe trabalha fora do escritório?"
              customLabel={<span>{outSideOffice} %</span>}
              onChange={(value: number | number[]) => setOutSideOffice(value)}
            />
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()} {...{disabled}}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default EmployeeInformationStep;
