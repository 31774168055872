import styled, { css } from "styled-components";
import { IResponsibleContainer } from "../styles";

export const ContentContainer = styled.div`
  display: flex;
  min-height: 100vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
  text-align: center;
  align-content: space-around;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.matrics.padding * 1.25}px;
`;

export const ResponsibleContainer = styled.div<IResponsibleContainer>`
  margin: auto;
  padding-top: ${({ theme, spacingY = true }) =>
    spacingY && theme.matrics.padding}px;
  padding-bottom: ${({ theme, spacingY = true }) =>
    spacingY && theme.matrics.padding}px;
  padding-left: ${({ theme, spacingX = true }) =>
    spacingX && theme.matrics.padding}px;
  padding-right: ${({ theme, spacingX = true }) =>
    spacingX && theme.matrics.padding}px;
  flex-wrap: nowrap;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    width: 70%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.md}px) {
    width: 70%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.lg}px) {
    width: 40%;
  }
  @media (min-width: ${({ theme }) => theme.matrics.xl}px) {
    width: 35%;
  }
`;

interface ContainerProps {
  backgroundColor?: string;
  backgroundImage?: boolean;
  textColor?: string;
}

export const Container = styled.div<ContainerProps>`
  margin-top: -1px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.greens.primary};

  ${(props) =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    background-size: 600px;
    background-position: center 135%;
  }
`;

export const Icon = styled.img`
  max-width: 40px;
  margin: auto;
  margin-bottom: 10px;
`

export const SocialIconsContainer = styled.img`
  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    width: 200px;
    margin: auto;
  }
`