import styled from "styled-components";

export interface DotProps {
  selected: boolean;
}

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const DotContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${({ theme }) => theme.matrics.padding}px;
  margin-bottom: ${({ theme }) => theme.matrics.padding * 1}px
`;

export const Dot = styled.button<DotProps>`
  display: flex;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: 2px solid ${({ theme }) => theme.colors.gray6};
  background-color: ${({ theme, selected }) => selected && theme.colors.gray6};

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    height: 15px;
    width: 15px;
  }
`;
