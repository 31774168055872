import * as React from "react";
import {useEffect} from 'react'
import { CarbonEmissionChart } from "../../../../components";
import { usePersonContext } from "../../context/personContext";
import { Container } from "../../../../components/CarbonEmissionChart/styles"

const COChart = () => {
  const { loading, emissionCarbon, dataCarbonEmission }  = usePersonContext()
  useEffect(() => {

  }, [dataCarbonEmission]);
  return (
    <React.Fragment>
      {!loading ? <CarbonEmissionChart data={dataCarbonEmission} counter={emissionCarbon} /> : (
         <Container />
      )}
    </React.Fragment>
  );
};
export default COChart;
