import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Container, TitleContainer, Title, IconButton } from './styles'
import { IoClose } from "react-icons/io5";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

type BasicModalType = {
  title: string,
  description: string,
  open: boolean,
  setOpen(value: boolean): void,
}

const BasicModal = ({ title, description, open, setOpen }: BasicModalType) => {
  // const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container>
          <TitleContainer>
            <Title>
              {title}
            </Title>
            <IconButton onClick={handleClose}> <IoClose /> </IconButton>
          </TitleContainer>
          <Typography id="modal-modal-description" sx={{ mt: 2, color: "#616161" }}>
            {description}
          </Typography>
        </Container>
      </Modal>
    </div>
  );
}

export default BasicModal