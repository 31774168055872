import * as React from 'react';
import Counter from '../Counter';
import { Field } from './styles';

type IFuelFieldParams = {
  label: string,
  onChange?(param:number) : void
};

const CounterField = ({label, onChange}:IFuelFieldParams) => {
  const handleChange = (value:number) => {
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Field>
      <div> {label} </div>
      <div>
        <Counter size="small" onChange={(value:number) => handleChange(value)} />
      </div>
    </Field>
  )
}

export default CounterField