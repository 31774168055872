import * as React from "react";
import InputNumber, { InputNumberProps } from "../InputNumber";
import SliderWithCounter from "../SliderWithCounter";
import { IconContainer } from "./styles";

export interface SliderWithIconProps extends InputNumberProps {
  size?: "small" | "medium";
  showCounter?: boolean;
  showFooter?: boolean;
  counterLabel?: string | React.ReactNode | number;
  icon?: string | React.ReactNode | number;
  isDigitable?: boolean;
  onChange?(param: number | number[]): void;
}

const SliderWithIcon = ({
  suffix,
  size,
  min = 0,
  max = 100,
  maxLength,
  placeholder,
  showCounter = true,
  showFooter,
  counterLabel,
  icon,
  isDigitable,
  inputIconAlign,
  mask,
  onChange,
}: SliderWithIconProps) => {

  const handleChangeInputNumber = (value: number) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="w-full">
      {icon && <IconContainer>{icon}</IconContainer>}
      {isDigitable ? (
        <InputNumber
          {...{ min, max, placeholder, inputIconAlign, maxLength }}
          mask={mask}
          suffix={suffix}
          onChange={handleChangeInputNumber}
        />
      ) : (
        <React.Fragment>
          <SliderWithCounter
            {...{ size, min, max, showCounter, showFooter, counterLabel }}
            onChange={(value: number | number[]) => onChange && onChange(value)}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default SliderWithIcon;
