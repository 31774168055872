import React from "react";
import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SwitchField,
  IconSwitch,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";

import BackgroundImage from "../../../../../assets/img/PF02.png";
import Car from "../../../../../assets/icons/PF02/hatchback.png";
import Motorcycle from "../../../../../assets/icons/PF02/motorcycle.png";
import Bike from "../../../../../assets/icons/PF02/bike.png";
import Walking from "../../../../../assets/icons/PF02/walking.png";

const MeansOfTransportStep = () => {
  const { changeStepScreen, setShowFuelStep } = usePersonContext();
  const [disabled, setDisabled] = useState(true);
  const [carSwitch, setCarSwitch] = useState(false);
  const [motoSwitch, setMotoSwitch] = useState(false);
  const [bikeSwitch, setBikeSwitch] = useState(false);
  const [onFootSwitch, setOnFootSwitch] = useState(false);

  const handleClickNext = (): void => {
    const showFuelStep = carSwitch || motoSwitch ? true : false;
    setShowFuelStep(showFuelStep);
    changeStepScreen(showFuelStep ? 1 : 2);
  };

  useEffect(() => {
    if (carSwitch || motoSwitch || bikeSwitch || onFootSwitch) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [carSwitch, motoSwitch, bikeSwitch, onFootSwitch]);

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              QUAIS MEIOS DE TRANSPORTE INDIVIDUAL VOCÊ COSTUMA UTILIZAR NO SEU
              DIA A DIA?
            </StepTitle>
          </div>
          <div>
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible>
          <LabelContainer>
            <span>Selecione todas as opções que você utiliza</span>
          </LabelContainer>

          <div className="w-7/12">
            <SwitchField
              label={<IconSwitch src={Car} />}
              checked={carSwitch}
              onChange={(value: boolean) => setCarSwitch(value)}
            />
          </div>
          <div className="w-7/12">
            <SwitchField
              label={<IconSwitch src={Motorcycle} />}
              checked={motoSwitch}
              onChange={(value: boolean) => setMotoSwitch(value)}
            />
          </div>
          <div className="w-7/12">
            <SwitchField
              label={<IconSwitch src={Bike} />}
              checked={bikeSwitch}
              onChange={(value: boolean) => setBikeSwitch(value)}
            />
          </div>
          <div className="w-7/12">
            <SwitchField
              label={<IconSwitch src={Walking} />}
              checked={onFootSwitch}
              onChange={(value: boolean) => setOnFootSwitch(value)}
            />
          </div>

          <ButtonContainer>
            <Button
              styleType="third"
              onClick={() => handleClickNext()}
              {...{ disabled }}
            >
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default MeansOfTransportStep;
