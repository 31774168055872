import * as React from "react";

export function replaceAll(str: string, find: any, replace: any) {
  try {
    return str.split(find).join(replace);
  } catch {
    return str;
  }
}

export function isObjEmpty(obj: any) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function removeNumberFormatting(value: string) {
  if (value === null || value === undefined) {
    return null;
  }
  value = replaceAll(value, "R$", "");
  value = replaceAll(value, "%", "");
  value = replaceAll(value, " ", "");
  value = replaceAll(value, ".", "");
  value = replaceAll(value, ",", ".");
  value = replaceAll(value, "_", "");
  if (value === "") {
    // value = null
  }

  return value;
}

export function addBrlCurrencyToNumber(number: number) {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatter.format(number);
}

export function currencyMask(
  e: React.FormEvent<HTMLInputElement>,
  maxLength: number = 7
) {
  let value = e.currentTarget.value;

  if (value.length > maxLength) {
    value = value.slice(0, value.length - 1);
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    e.currentTarget.value = value;
    return e;
  } else {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    e.currentTarget.value = value;
    return e;
  }
}

export function meterMask(
  e: React.FormEvent<HTMLInputElement>,
  maxLength: number = 6
) {
  let value = e.currentTarget.value;
  let number;
  value = value.replace(/\D/g, "");

  number = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  // value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  if (number.length > maxLength) {
    e.currentTarget.value = "9999,99";
    return e;
  }

  e.currentTarget.value = value;
  return e;
}

export function numberMask(
  e: React.FormEvent<HTMLInputElement>,
  maxLength: number = 7,
  min = 0,
  max = 1000
) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");

  if (parseInt(value) > max) {
    value = value.slice(0, value.length - 1);
    value = value.replace(/(\d)(\d{3}$)/, "$1.$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    e.currentTarget.value = value;
    return e;
  } else {
    value = value.replace(/(\d)(\d{3}$)/, "$1.$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    e.currentTarget.value = value;
    return e;
  }
}

export const numberFormat = (param:string) => {
  let value = param
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  return value
}
