import * as React from 'react';
import styled from "styled-components";
import { IoArrowBackOutline } from "react-icons/io5";

interface IButton {
  color?: string,
  size?: number,
  onClick?: any
}

const Icon = styled(IoArrowBackOutline).attrs(({ theme, size, color }) => ({
  color: color || theme.colors.gray2,
  size: size || 30
}))`
  cursor: pointer
`

const BackButton = ({color, size, onClick}: IButton) => {
  return (
    <span onClick={onClick}>
      <Icon {...{color, size}} />
    </span>
  )
}

export default BackButton