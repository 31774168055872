import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "..";
import { Header, SubTitle, Title, Image } from "../styles";
import {
  Container,
  ResponsibleContainer,
  ContentContainer,
  SocialIconsContainer,
} from "./styles";
import CarbonEmissionChart from "./CarbonEmissionChart";
import { Idata } from "../CarbonEmissionChart";
import CarbonEmissionInformation from "./CarbonEmissionInformation";
import { ThemeContext } from "styled-components";
import EmissionEquivalence from "./EmissionEquivalence";

import ImageLP1 from "../../assets/img/LP1.png";
import SocialIcons from "../../assets/icons/social.png";

import Result from "../../assets/img/resultados.png";

interface ResultPageModelProps {
  emissionCarbon?: number;
  data?: Idata[];
  onBack?(): void;
}

const ResultPageModel = ({
  emissionCarbon,
  data,
}: ResultPageModelProps) => {
  const themeContext = useContext(ThemeContext);

  const quantity = emissionCarbon ? Math.ceil(emissionCarbon) : 0;
  const dataCarbonEmission = data?.filter((d) => d.value > 0);

  const navigate = useNavigate();
  const onClickButton = (route: string) => {
    navigate(route);
  };

  return (
    <>
      <Container>
        <ResponsibleContainer spacingX spacingY={false}>
          <div className="flex pb-10 pt-10 text-center content-around justify-center flex-wrap">
            <div className="flex flex-col gap-5 mb-5 ">
              <Header>
                <SubTitle>Sua emissão total foi de</SubTitle>
                <span className="text-4xl">
                  {emissionCarbon ? emissionCarbon.toFixed(2) : 0}
                </span>
                <p>Toneladas de CO2</p>
              </Header>

              <a
                href={`https://energy-green.io/checkout/?add-to-cart=1728&quantity=${quantity}`}
                target="_blank"
              >
                <Button>Neutralizar emissões</Button>
              </a>
            </div>
          </div>
        </ResponsibleContainer>
      </Container>
      <Container backgroundColor={themeContext.colors.backgroundStep}>
        <div className="flex flex-col md:flex-row w-8/12 m-auto py-10 items-center">
          <div className="w-full flex justify-center">
            <CarbonEmissionChart
              counter={emissionCarbon}
              data={dataCarbonEmission}
            />
          </div>

          <CarbonEmissionInformation
            counter={emissionCarbon}
            data={dataCarbonEmission}
          />
        </div>
      </Container>
      <Container>
        <ResponsibleContainer spacingX spacingY={false}>
          <ContentContainer>
            <EmissionEquivalence emissionCarbon={emissionCarbon} />

            <div className="w-full mt-3 mb-5">
              <img src={Result} />
            </div>

            <div className="flex flex-col gap-5">
              <Header>
                <SubTitle>Neutralize agora</SubTitle>
                <Title>suas emissões</Title>
              </Header>

              <div className="w-full">
                <p>
                  Com os projetos da Egreen é possivel neutralizar todas as suas
                  emissões de carbono com um único clique
                </p>
              </div>

              <div className="w-full">
                <Image src={ImageLP1} />
              </div>

              <div className="flex justify-center w-full">
                <a
                  href={`https://energy-green.io/checkout/?add-to-cart=1728&quantity=${quantity}`}
                  target="_blank"
                >
                  <Button styleType="secundary">Netralizar agora</Button>
                </a>
              </div>
            </div>

            <div className="flex flex-col gap-5">
              <Header>
                <SubTitle>Compartilhe com seus amigos</SubTitle>
                <Title>E nos ajude a salvar o mundo</Title>
              </Header>

              <div className="w-full">
                <SocialIconsContainer src={SocialIcons} />
              </div>
            </div>
          </ContentContainer>
        </ResponsibleContainer>
      </Container>
    </>
  );
};

export default ResultPageModel;
