import { useContext } from "react";
import { Container } from "../styles";
import {
  ContentContainer,
  Header,
  Title,
  SubTitle,
  Image,
} from "../../../../components/styles";
import { ResponsibleContainer } from "../styles";
import Information from "./Information";
import { ThemeContext } from "styled-components";

import ImageLP2 from "../../../../assets/img/LP2.png";


const InformationHome = () => {
  const themeContext = useContext(ThemeContext);

  const textColor = themeContext.colors.greens.primary

  return (
    <Container backgroundColor="#FFF" {...{textColor}}>
      <ResponsibleContainer spacingX spacingY>
        <ContentContainer>
          <div className="w-full">
            <Image src={ImageLP2} />
          </div>
          <Header>
            <SubTitle {...{textColor}}>Suas ações</SubTitle>
            <Title>Contam para todos</Title>
          </Header>

          <Information
            percentage={87}
            description="da população brasileira prefere comprar produtos e serviços de empresas sustentáveis"
          />
          <Information
            percentage={85}
            description="dos brasileiros dizem que se sentem melhor comprando produtos sustentáveis"
            source="FIEP, 2019"
          />
          <Information
            percentage={98}
            description="dos investidores realizam pesquisas sobre indicadores não financeiros, antes de investir"
            source="Relatório EY"
          />
        </ContentContainer>
      </ResponsibleContainer>
    </Container>
  );
};

export default InformationHome;
