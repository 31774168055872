import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SliderField,
  FieldSwitcher,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";
import BackgroundImage from "../../../../../assets/img/PF03.png";

const FuelStep = () => {
  const {
    regularGasoline,
    setRegularGasoline,
    ethanol,
    setEthanol,
    diesel,
    setDiesel,
    gnv,
    setGnv,
    biodiesel,
    setBiodiesel,
    changeStepScreen,
  } = usePersonContext();

  const [isDigitable, setIsDigitable] = useState(false);
  const [btnBisabled, setBtnDisabled] = useState(false)

  const fieldsProps = {
    min: 0,
    max: 1000,
    showCounter: true,
    showFooter: true,
    isDigitable: isDigitable,
    suffix: "R$",
    placeholder: "0,00",
  };

  const moneyFormating = (value: number | number[]) => {
    const numberFormated = value < 10 && value > 0 ? "0" + value : value;
    return "R$ " + numberFormated + ",00";
  };

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  const clearParams = () => {
    setRegularGasoline(0);
    setEthanol(0);
    setDiesel(0);
    setGnv(0);
    setBiodiesel(0);
  };

  useEffect(() => {
    clearParams();
  }, [isDigitable]);

  useEffect(() => {
    if (regularGasoline > 0 || diesel > 0 || biodiesel > 0 || ethanol > 0 || gnv > 0) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  }, [regularGasoline, diesel, biodiesel, ethanol, gnv]);

  return (
    <Container backgroundColor="#B0E2EC">
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              EM MÉDIA, QUAL É O SEU GASTO COM COMBUSTÍVEL POR MÊS?
            </StepTitle>
          </div>
          <div>
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Selecione ou digite os valores</span>
          </LabelContainer>

          <SliderField
            label="Gasolina Comum"
            alignLabel="right"
            mask="currency"
            {...fieldsProps}
            counterLabel={moneyFormating(regularGasoline)}
            onChange={(newValue: number | number[]) => {
              setRegularGasoline(!newValue ? 0 : newValue);
            }}
          />

          <SliderField
            label="Etanol"
            alignLabel="right"
            mask="currency"
            {...fieldsProps}
            counterLabel={moneyFormating(ethanol)}
            onChange={(newValue: number | number[]) => {
              setEthanol(!newValue ? 0 : newValue);
            }}
          />
          <SliderField
            label="Diesel"
            alignLabel="right"
            mask="currency"
            {...fieldsProps}
            counterLabel={moneyFormating(diesel)}
            onChange={(newValue: number | number[]) => {
              setDiesel(!newValue ? 0 : newValue);
            }}
          />
          <SliderField
            label="GNV"
            alignLabel="right"
            mask="currency"
            {...fieldsProps}
            counterLabel={moneyFormating(gnv)}
            onChange={(newValue: number | number[]) => {
              setGnv(!newValue ? 0 : newValue);
            }}
          />
          <SliderField
            label="Biodiesel"
            alignLabel="right"
            mask="currency"
            {...fieldsProps}
            counterLabel={moneyFormating(biodiesel)}
            onChange={(newValue: number | number[]) => {
              setBiodiesel(!newValue ? 0 : newValue);
            }}
          />

          <FieldSwitcher
            onChange={(checked: boolean) => setIsDigitable(checked)}
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()} disabled={btnBisabled}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default FuelStep;
