import { useState } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  InputText,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import { useNavigate } from "react-router";

import Image from "../../../../../assets/img/PJ01.png";

const CompanyNameStep = () => {
  const { changeStepScreen } = useCompanyContext();
  const [companyName, setCompanyName] = useState("");
  const navigate = useNavigate();

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => navigate("/")} />
            <StepTitle>PARA COMEÇAR, DIGA O NOME DA SUA EMPRESA</StepTitle>
          </div>
          <div></div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <div className="w-full"> </div>
          <LabelContainer>
            <span>Digite no espaço abaixo</span>
          </LabelContainer>

          <InputText
            onChange={(value: string) => setCompanyName(value)}
          />

          <ButtonContainer>
            <Button
              styleType="third"
              onClick={() => handleClickNext()}
              disabled={companyName.length < 2 ? true : false}
            >
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default CompanyNameStep;
