import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Counter = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray4};
`;

export const Label = styled.span`
  font-size: 0.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray2};
`;

