import styled from "styled-components";
import { FaLeaf } from "react-icons/fa";


export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${({ theme }) => theme.matrics.padding / 2}px ${({ theme }) => theme.matrics.padding}px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 999;
  box-shadow: 0px 1px 10px ${({ theme }) => theme.colors.shadow};
`

export const LogoContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor:pointer;
`
export const Title = styled.span`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.green};
  text-shadow: 0 0 1px ${({ theme }) => theme.colors.shadow};
`
export const Logo = styled(FaLeaf).attrs(({ theme, size }) => ({
  size: size || "1rem",
  color: theme.colors.green,
}))``