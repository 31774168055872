import { useState } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  Counter,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import Image from "../../../../../assets/img/PJ09.png";
import COChart from "../../COChart";

const AirTravelStep = () => {
  const { lastYear, changeStepScreen, setNationalTrip, setInternationalTrip } = useCompanyContext();

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
            {`EM ${lastYear}, QUANTAS VIAGENS DE AVIÃO FORAM FEITAS POR SEUS FUNCIONÁRIOS PARA A EMPRESA?`}
            </StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Selecione a quantidade</span>
          </LabelContainer>

          <div className="w-8/12">
            <div className="w-full text-center text-xl mb-5">Nacional</div>
            <Counter onChange={(newValue) => setNationalTrip(newValue)} />
          </div>
          <div className="w-8/12">
            <div className="w-full text-center text-xl mb-5">Internacional</div>
            <Counter onChange={(newValue) => setInternationalTrip(newValue)} />
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default AirTravelStep;
