import {
  BackButton,
  StepTitle,
  Button,
  InputNumber,
  FieldWithLabelAndIcon,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";

import Image from "../../../../../assets/img/PJ1011.png";
import Icon from "../../../../../assets/icons/PJ11.png";
import { CustomLabelCounter } from "../../../../../components/FieldWithLabelAndIcon/styles";
import COChart from "../../COChart";

const CookingGasStep = () => {
  const { changeStepScreen, setGasConsumption } = useCompanyContext();

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              SUA EMPRESA UTILIZA GÁS DE COZINHA? EM MÉDIA, QUAL É O GASTO
              MENSAL?
            </StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <div className="w-full"> </div>

          <FieldWithLabelAndIcon
            inputType="inputNumber"
            mask="currency"
            icon={Icon}
            label="Digite o valor ou deixe R$ 0,00 caso não utilize"
            placeholder="0,00"
            suffix={<CustomLabelCounter>R$ </CustomLabelCounter>}
            onChange={(value: number | number[]) => setGasConsumption(value)}
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default CookingGasStep;
