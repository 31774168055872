import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SliderWithIcon,
  IconSliderWithIcon,
  Switch,
  FieldSwitcher,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";

import Image from "../../../../../assets/img/PF11.png";
import LightningIcon from "../../../../../assets/icons/PF11.png";

const EnergyConsumption = () => {
  const {
    showGasStep,
    changeStepScreen,
    eletricityConsumption,
    setEletricityConsumption,
  } = usePersonContext();
  const [isDigitable, setIsDigitable] = useState(false);

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  useEffect(() => {
    setEletricityConsumption(0);
  }, [isDigitable]);

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(showGasStep ? -1 : -2)} />
            <StepTitle>
              EM MÉDIA, QUAL O SEU GASTO COM ENERGIA ELÉTRICA POR MÊS?
            </StepTitle>
          </div>
          <div>
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Selecione ou digite o valor</span>
          </LabelContainer>

          <div className="w-10/12">
            <SliderWithIcon
              showFooter
              min={0}
              max={1000}
              placeholder="0,00"
              suffix="R$"
              mask="currency"
              icon={<IconSliderWithIcon src={LightningIcon} />}
              isDigitable={isDigitable}
              onChange={(newValue: number | number[]) =>
                setEletricityConsumption(!newValue ? 0 : newValue)
              }
              counterLabel={<span>R$ {eletricityConsumption},00</span>}
            />
          </div>

          <FieldSwitcher
            onChange={(checked: boolean) => setIsDigitable(checked)}
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default EnergyConsumption;
