import styled, { css } from "styled-components";

type IIConContainer = {
  left?: boolean;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div<IIConContainer>`
  margin-right: ${({ theme }) => theme.matrics.padding}px;

  ${({ theme, left }) =>
    left &&
    css<IIConContainer>`
      margin-left: ${theme.matrics.padding}px;
      margin-right: 0;
    `}
`;

export const CounterText = styled.div`
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: -8px;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 2px solid #f5f5f5;

  input {
    border: none;
  }

  input:focus {
    border: none;
  }
  /* background-color: pink */
`;

export const Preffix = styled.div`
  position: absolute;
  top: 20%;
  left: 0px;
`;
