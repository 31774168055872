/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from "react";
import { CountContainer, Button } from "./styles";

export interface CounterType {
  onChange(param: number): void;
  size?: "small";
  defaultValue?: number;
  min?: number;
  max?: number;
}

const Counter = ({
  size,
  onChange,
  defaultValue,
  min = 0,
  max,
}: CounterType) => {
  const [count, setCount] = useState(defaultValue ? defaultValue : 0);
  const [countKey, setCountKey] = useState(0);

  const handleClick = (value: number): void => {
    setCountKey(countKey + 1);

    if (value > 0) {
      setCount(count + 1);
    }

    if (value < 0) {
      count > min && setCount(count - 1);
      setCountKey(countKey + 1);
    }
  };

  const getMofier = () => {
    if (count > 0) return "counter__tile--increment";
    if (count < 0) return "counter__tile--decrement";
  };

  useEffect(() => {
    onChange(count);
  }, [count, countKey]);

  return (
    <CountContainer>
      <Button size={size} onClick={() => handleClick(-1)}>
        -
      </Button>
      <span className={`counter__title ${getMofier()}`}>{count}</span>
      <Button size={size} onClick={() => handleClick(1)}>
        +
      </Button>
    </CountContainer>
  );
};
export default Counter;
