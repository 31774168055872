import styled, {css} from "styled-components";
import { TextColorProp } from "../../../../../components/styles";

export const Percentage = styled.div`
  display: flex;
  width: 30%;
  font-weight: 900;
  justify-content: center;
  font-size: 1.5rem;
`

export const Text = styled.div<TextColorProp>`
  width: 70%;
  ${props => props.textColor && css`
    color: ${props.textColor};
    p {color: ${props.textColor}}
  `}

  p {
    text-align: left;
    font-weight: bold;
  }

  & > div {
    text-align: right;
    width: 100%;
  }
`