import { Slider } from "@mui/material";
import { styled } from "@mui/system";

const SliderCustomized = styled(Slider)(({theme}) => ({
  color: "#fff",
  width: "100%",
  "& .MuiSlider-thumb": {
    borderRadius: "15px",
    border: "solid 2px #fff",
    color: "#2B9C1A",
    width: '15px',
    height: '15px',
  },
}));

export default SliderCustomized;
