//Remover essa etapa
// import { useState } from "react";
import * as React from "react";
import { useState } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  CounterField,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";
import BackgroundImage from "../../../../../assets/img/PF12.png";
import ItemsPurchasedStepSlider from "./ItemsPurchasedStepSlider";
import { Dot, DotContainer } from "./styles";

const ItemsPurchasedStep = () => {
  const {
    lastYear,
    loading,
    setCoats,
    setSockes,
    setPajamas,
    setTShirts,
    setTablests,
    setNotebooks,
    setSmartphones,
    setComputer,
    changeStepScreen,
  } = usePersonContext();

  const [dotSelected, setDotSelected] = useState(0);

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  const NextSlide = () => {
    if (dotSelected < 2) {
      setDotSelected(dotSelected + 1);
      return;
    }
    handleClickNext();
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              {`EM MÉDIA, QUAL A QUANTIDADE DE ITENS DE CADA GRUPO, VOCÊ COMPROU
              NO ANO DE ${lastYear} ?`}
            </StepTitle>
          </div>
          <COChart />
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible>
          <div className="hidden flex-col gap-3 md:hidden">
            <CounterField
              label="Casacos, jaquetas, vestidos, camisas"
              onChange={(newValue: number) => setCoats(newValue)}
            />
            <CounterField
              label="Meias, roupas íntimas, roupas de banho"
              onChange={(newValue: number) => setSockes(newValue)}
            />
            <CounterField
              label="Pijamas, gravatas, cachecóis"
              onChange={(newValue: number) => setPajamas(newValue)}
            />
            <CounterField
              label="Camisetas, calças, saias, shorts, bermudas e roupas de treino"
              onChange={(newValue: number) => setTShirts(newValue)}
            />
            <CounterField
              label="Tablets, dispositivos com comando de fala"
              onChange={(newValue: number) => setTablests(newValue)}
            />
            <CounterField
              label="Notebooks"
              onChange={(newValue: number) => setNotebooks(newValue)}
            />
            <CounterField
              label="Smartphones, Celulares"
              onChange={(newValue: number) => setSmartphones(newValue)}
            />
            <CounterField
              label="Computadores, monitores e TV's"
              onChange={(newValue: number) => setComputer(newValue)}
            />
          </div>

          <div className="w-full"> </div>

          <ItemsPurchasedStepSlider {...{ dotSelected, setDotSelected }} />

          <div className="block">
            <DotContainer>
              <Dot
                selected={dotSelected === 0 ? true : false}
                onClick={() => setDotSelected(0)}
              />
              <Dot
                selected={dotSelected === 1 ? true : false}
                onClick={() => setDotSelected(1)}
              />
              <Dot
                selected={dotSelected === 2 ? true : false}
                onClick={() => setDotSelected(2)}
              />
            </DotContainer>
            <ButtonContainer>
              <Button styleType="third" onClick={() => NextSlide()}>
                Avançar <IoArrowForwardSharp size={20} />
              </Button>
            </ButtonContainer>
          </div>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default ItemsPurchasedStep;
