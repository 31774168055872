import * as React from "react";
import { Container } from "../styles";
import {
  ContentContainer,
  Header,
  Title,
  SubTitle,
  Image,
} from "../../../../components/styles";
import { ResponsibleContainer } from "../styles";
import ImageLP1 from "../../../../assets/img/LP1.png";

const HomeAbout = () => {
  return (
    <>
      <Container>
        <ResponsibleContainer spacingX spacingY={false}>
          <ContentContainer>
            <Header>
              <SubTitle>O que é a</SubTitle>
              <Title>
                Calculadora de CO<sub>2</sub>
              </Title>
            </Header>

            <div className="w-full">
              <p>
                Nossas atividades do dia a dia emitem, direta ou
                indiretamente, gases que contribuem para o agravamento do efeito
                estufa.
              </p>
            </div>
            <div className="w-full">
              <p>
                Mas, a boa notícia, é que agora você ou sua empresa pode
                compensar a quantidade de CO<sub>2</sub> emitida na atmosfera
                com créditos de carbono.
              </p>
            </div>
            <div className="w-full">
              <p>
                Com a Calculadora de Cabono da <strong>Egreen</strong>, você
                consegue calcular quantas toneladas de carbono foram emitidas na
                atmosfera de forma gratuíta. Ao final do processo, é possível
                adquirir créditos de carbono de diversos projetos licenciados
                para compensar toda quantidade emitida por suas atividades
                diárias ou da sua empresa.
              </p>
            </div>

            <div className="w-full">
              <Image src={ImageLP1} />
            </div>
            <div className="w-full">
              <p>
                <strong>Seja Carbono Zero com a Egreen</strong>
              </p>
            </div>
          </ContentContainer>
        </ResponsibleContainer>
      </Container>
    </>
  );
};
export default HomeAbout;
