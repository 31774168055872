import { useState, useEffect } from "react";
import { apiIBGE } from "../../../../../services/api";

interface IRegion {
  id: number;
  nome: string;
  sigra: string;
}
interface IState {
  id: number;
  nome: string;
  sigla: string;
  regiao: IRegion;
}

const StateTest = () => {
  const [list, setList] = useState<IState[]>([]);

  const addToLIst = async () => {
    try {
      const response = await apiIBGE.get("api/v1/localidades/estados");

      const { data } = response;
      const dataSort = data.sort((a: IState, b: IState) =>
        a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0
      );
      setList(dataSort);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    addToLIst()
  }, []);

  return (
    <div className="p-5 text-black">
      <ul>
        {list.map((item) => (
          <li role="presentation">{item.nome}</li>
        ))}
      </ul>
    </div>
  );
};

export default StateTest;
