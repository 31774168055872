import Switch from "../Switch";

export interface FieldSwitcherProps {
 onChange?(checked:boolean): void
 firstLabel?:string
 secondLabel?: string
}
const FieldSwitcher = ({onChange, firstLabel, secondLabel}:FieldSwitcherProps) => {


  return (
    <div className="w-full text-center">
      {firstLabel ?? "Barra de rolagem"} &nbsp;{" "}
      <Switch
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => onChange && onChange(checked)}
      />{" "}
      &nbsp; {secondLabel ?? "Digitar valores"}
    </div>
  );
};
export default FieldSwitcher;
