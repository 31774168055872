import * as React from "react";
import { currencyMask, meterMask } from "../../utils";
import { InputContainer, InputContainerProps } from "./styles";

export interface InputNumberProps extends InputContainerProps {
  min?: number
  max?: number
  maxLength?: number
  placeholder?: string;
  suffix?: string | React.ReactNode
  mask?: "currency" | "meter"
  onChange?(param: string): void
}

const InputText = ({
  suffix,
  min,
  max,
  placeholder,
  inputIconAlign,
  fontSize,
  onChange,
}: InputNumberProps) => {
  const handleKeyUp = (e: React.FormEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value
    if (onChange) {
      onChange(value && value);
    }
  };

  return (
    <InputContainer inputIconAlign={inputIconAlign} fontSize={fontSize}>
      <div className="inputIcon"> {suffix && suffix} </div>
      <input {...{ min, max, placeholder }} onKeyUp={handleKeyUp} />
    </InputContainer>
  );
};

export default InputText;
