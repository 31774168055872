import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 45vh;
  width: 100%;
  background-color: green
`

const Form:React.FC<React.PropsWithChildren> = ({children}) => {
  return(
    <Container>
      {children}
    </Container>
  )
}
export default Form