import * as React from "react";
import { usePersonContext } from "../../context/personContext";
import {
  StateStep,
  MeansOfTransportStep,
  FuelStep,
  UsePublicTransportStep,
  PublicTransportStep,
  TimeSpentStep,
  AirTravelStep,
  PersonQuantityStep,
  GasTypesStep,
  GasStep,
  EnergyConsumptionStep,
  ItemsPurchasedStep,
  CalorieConsumptionStep,
  FoodConsumptionStep,
  BasbageBagStep,
  GeneratedWaste,
} from "../Steps";
import { ResultPageModel } from "../../../../components";

const FormContent = () => {
  const { stepScreen, emissionCarbon, dataCarbonEmission, changeStepScreen } = usePersonContext();

  // List of steps to render each per number of 'stepScreen'
  const steps = [
    <StateStep />,
    <MeansOfTransportStep />,
    <FuelStep />,
    <UsePublicTransportStep />,
    <PublicTransportStep />,
    <TimeSpentStep />,
    <AirTravelStep />,
    <PersonQuantityStep />,
    <GasTypesStep />,
    <GasStep />,
    <EnergyConsumptionStep />,
    <ItemsPurchasedStep />,
    <CalorieConsumptionStep />,
    <FoodConsumptionStep />,
    <BasbageBagStep />,
    <GeneratedWaste />,
    <ResultPageModel
      emissionCarbon={emissionCarbon}
      data={dataCarbonEmission}
      onBack={() => changeStepScreen(-1)}
    />,
  ];

  return (
    <React.Fragment>
      {steps.map((component, index) => (
        <div style={{ display: stepScreen === index ? "block" : "none" }}>
          {component}
        </div>
      ))}
    </React.Fragment>
  );
};
export default FormContent;
