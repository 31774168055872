import * as React from "react";
import { useState } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SliderField,
  Modal,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import { IconCustomized } from "./styles";
import COChart from "../../COChart";
import BackgroundImage from "../../../../../assets/img/PF16.png";

type IconType = {
  label: string | React.ReactNode;
  onClick(): void;
};
const Icon = ({ label, onClick }: IconType) => {
  return (
    <div className="flex" onClick={onClick}>
      <IconCustomized>?</IconCustomized> {label}{" "}
    </div>
  );
};

const GeneratedWaste = () => {
  const {
    loading,
    changeStepScreen,
    recyclingPercentage,
    setRecyclingPercentage,
    compostPercentage,
    setCompostPercentage,
    barbageTruckPercentage,
    setBarbageTruckPercentage,
  } = usePersonContext();

  const [recyclingOpen, setRecyclingOpen] = useState(false)

  // Disables
  const [recyclingPercentageDisabled, setRecyclingPercentageDisabled] =
    useState(false);
  const [compostPercentageDisabled, setCompostPercentageDisabled] =
    useState(false);
  const [barbageTruckPercentageDisabled, setBarbageTruckPercentageDisabled] =
    useState(false);

  const messages = [
    "Reciclagem é o processo que visa transformar materiais usados em novos produtos para sua reutilização. Por este processo, materiais que seriam destinados ao lixo permanente podem ser reaproveitados. É um termo que tem sido cada vez mais utilizado como alerta para a importância da preservação dos recursos naturais e do meio ambiente.",
  ];
  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  const verifyPercentage = (
    recycling?: number | number[],
    compost?: number | number[],
    barbageTruck?: number | number[]
  ) => {
    if (recycling + compostPercentage + barbageTruckPercentage <= 100) {
      setRecyclingPercentage(recycling);
    } else if (recycling + compostPercentage + barbageTruckPercentage > 100) {
      setRecyclingPercentage(100 - compostPercentage - barbageTruckPercentage);
    }

    if (compost + recyclingPercentage + barbageTruckPercentage <= 100) {
      setCompostPercentage(compost);
    } else if (compost + recyclingPercentage + barbageTruckPercentage > 100) {
      setCompostPercentage(100 - recyclingPercentage - barbageTruckPercentage);
    }

    if (recyclingPercentage + compostPercentage + barbageTruck <= 100) {
      setBarbageTruckPercentage(barbageTruck);
    } else if (recyclingPercentage + compostPercentage + barbageTruck > 100) {
      setBarbageTruckPercentage(100 - recyclingPercentage - compostPercentage);
    }
  };

  return (
    <React.Fragment>
      <Modal
        title="? Reciclagem"
        description={messages[0]}
        open={recyclingOpen}
        setOpen={setRecyclingOpen}
      />

      <Container>
        <ResponsibleContainer spacingX spacingY>
          <StepHeader>
            <div>
              <BackButton onClick={() => changeStepScreen(-1)} />
              <StepTitle>
                QUAL É O DESTINO DO LIXO GERADO EM SUA RESIDÊNCIA?
              </StepTitle>
            </div>
            <div>
              <COChart />
            </div>
          </StepHeader>
        </ResponsibleContainer>

        <ImageContainer src={BackgroundImage} />

        <StepForm>
          <FormResponsible spacingX={true} spacingY={true}>
            <LabelContainer>
              <span>Defina uma porcentagem aproximada.</span>
            </LabelContainer>

            <SliderField
              value={recyclingPercentage}
              disabled={recyclingPercentageDisabled}
              showCounter
              counterLabel={<span>{recyclingPercentage + "%"}</span>}
              onChange={(value: number | number[]) => verifyPercentage(value)}
              label={
                <Icon
                  label="Reciclagem"
                  onClick={() => setRecyclingOpen(true)}
                />
              }
            />
            <SliderField
              disabled={compostPercentageDisabled}
              showCounter
              counterLabel={<span>{compostPercentage + "%"}</span>}
              onChange={(value: number | number[]) =>
                verifyPercentage(undefined, value)
              }
              label="Compostagem"
              value={compostPercentage}
            />
            <SliderField
              disabled={barbageTruckPercentageDisabled}
              showCounter
              counterLabel={<span>{barbageTruckPercentage + "%"}</span>}
              onChange={(value: number | number[]) =>
                verifyPercentage(undefined, undefined, value)
              }
              label="Caminhão de lixo"
              value={barbageTruckPercentage}
            />

            <ButtonContainer>
              <Button styleType="third" onClick={() => handleClickNext()}>
                Avançar <IoArrowForwardSharp size={20} />
              </Button>
            </ButtonContainer>
          </FormResponsible>
        </StepForm>
      </Container>
    </React.Fragment>
  );
};

export default GeneratedWaste;
