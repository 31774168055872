import styled from "styled-components";

export const SliderHeader = styled.div<{size?:string}>`
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: ${({size}) => size === 'small' ? -0.9 : 0.5}rem;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    margin-bottom: ${({size}) => size === 'small' ? -0.7 : 0.5}rem;
  }
`;

export const SliderBody = styled.div<{spacingX?: boolean}>`
  padding: 0 ${({theme, spacingX}) => spacingX ? theme.matrics.padding / 1.25 : 0}px;
`

export const SliderFooter = styled.div<{size?:string}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -1.2rem;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    margin-top: ${({size}) => size === 'small' ? -0.8 : 0}rem
    /* margin-top: -0.8rem; */
  }



  span {
    font-size: ${({size}) => size === 'small' ? 0.6 : 0.8}rem;
    color: #e9e9e9;
  }
`
