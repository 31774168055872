import { useState } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  Select,
  MenuItem,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import FormControl from "@mui/material/FormControl";

import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import Image from "../../../../../assets/img/PJ02.png";
import { SelectChangeEvent } from "@mui/material";

const CompanySegmentStep = () => {
  const { changeStepScreen } = useCompanyContext();
  const [segmentSelected, setSegmentSelected] = useState<unknown | number>()

  const segments = [
    { id: 0, name: "Agropecuária" },
    { id: 1, name: "Construção" },
    { id: 2, name: "Serviços" },
    { id: 3, name: "Consultoria" },
    { id: 4, name: "Transporte e logística" },
    { id: 5, name: "Varejo" },
    { id: 6, name: "Turismo" },
    { id: 7, name: "Tecnologia" },
    { id: 8, name: "ONG" },
    { id: 9, name: "Outro" },
  ];

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>Qual é o setor da sua empresa?</StepTitle>
          </div>
          <div></div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Escolha o segmento</span>
          </LabelContainer>
          <FormControl
            variant="standard"
            sx={{
              minWidth: "100%",
            }}
          >
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              // value={state}
              onChange={(e:SelectChangeEvent<unknown>) => setSegmentSelected(e.target.value)}
              label="Age"
            >
              <MenuItem value={undefined}>
                {" "}
                &nbsp;&nbsp;&nbsp; Selecione
              </MenuItem>

              {segments?.map((d) => (
                <MenuItem value={d.id}>
                  {" "}
                  &nbsp;&nbsp;&nbsp; {d.name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()} disabled={!segmentSelected ? true : false}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default CompanySegmentStep;
