import styled from "styled-components";
import Counter from ".";
import theme from "../../config/theme/light";

export type ButtonType = {
  size?: "small" | "default";
};

export const CountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Button = styled.button<ButtonType>`
  background-color: ${({ theme }) => theme.colors.gray3};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ size }) => (size === "small" ? 1 : 1.6)}rem;
  font-weight: 600;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  width: ${({ size }) => (size === "small" ? "1.7" : "3")}rem;
  height: ${({ size }) => (size === "small" ? "1.7" : "3")}rem;
  transition: 0.3s;

  &:active {
    transform: scale(1.3);
  }
`;

CountContainer.defaultProps = {
  theme: theme,
};
Button.defaultProps = {
  theme: theme,
};
