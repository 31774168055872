import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  Counter,
  Switch,
  InputNumber,
  FieldSwitcher,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { FcLandscape } from "react-icons/fc";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";
import BackgroundImage from "../../../../../assets/img/PF15.png";

const BasbageBagStep = () => {
  const { loading, setBarbageBag, changeStepScreen } = usePersonContext();
  const [isDigitable, setIsDigitable] = useState(false);

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  useEffect(() => {
    setBarbageBag(0);
  }, [isDigitable]);

  return (
    <Container backgroundColor="#C0E6F3">
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              EM MÉDIA, QUANTOS SACOS DE LIXO VOCÊ UTILIZA EM SUA RESIDÊNCIA POR
              SEMANA?
            </StepTitle>
          </div>
          <div>{!loading && <COChart />}</div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>
              Selecione a quantidade levando em consideração um saco de tamanho
              médio (30 litros)
            </span>
          </LabelContainer>

          {isDigitable ? (
            <div className="w-8/12">
              <InputNumber
                min={0}
                max={100}
                placeholder="0"
                onChange={(newValue: number) => setBarbageBag(newValue)}
              />
            </div>
          ) : (
            <div className="w-8/12">
              <Counter
                onChange={(newValue: number) => setBarbageBag(newValue)}
              />
            </div>
          )}

          <FieldSwitcher
            firstLabel="Contador"
            secondLabel="Digitar"
            onChange={(checked: boolean) => setIsDigitable(checked)}
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default BasbageBagStep;
