import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { Container, ImageContainer } from "./styles";
import { CarbonEmissionChartProps } from "../../CarbonEmissionChart";
import Image from "../../../assets/img/LP2.png";
import { returnIcon } from "./return-icons";
import {
  returnBorderColor,
  returnColor,
} from "../../../utils/enums/return-colors";

const RADIAN = Math.PI / 180;

const CarbonEmissionChart = ({ data, counter }: CarbonEmissionChartProps) => {
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const radiusForImage = innerRadius + (outerRadius - innerRadius) * 0.5;
    const xImage = cx + radiusForImage * Math.cos(-midAngle * RADIAN) - 20;
    const yImage = cy + radiusForImage * Math.sin(-midAngle * RADIAN) - 20;

    const dataPercentage = (
      data &&
      counter &&
      (data[index].value * 100) / counter
    )?.toFixed(2);

    return (
      <>
        {
          data && returnIcon({key: data[index].key, x:xImage ,y:yImage})
        }

        {/* <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          className="heavy"
        >
          {dataPercentage}%
        </text>
        <text
          x={x + 70}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {data && data[index].name}
        </text> */}
      </>
    );
  };

  return (
    <Container>
      <PieChart width={360} height={300}>
        <Pie
          data={data}
          dataKey="value"
          fill="#8e8d96"
          innerRadius={85}
          outerRadius={93}
          labelLine={false}
        >
          {data &&
            data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={returnBorderColor({key:data[index].key})}
              />
            ))}
        </Pie>
        <Pie
          data={data}
          dataKey="value"
          fill="#8e8d96"
          innerRadius={40}
          outerRadius={88}
          label={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            percent,
            index,
          }) =>
            renderCustomizedLabel({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              percent,
              index,
            })
          }
          labelLine={false}
        >
          {data &&
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={returnColor({key: data[index].key})} />
            ))}
        </Pie>
      </PieChart>

      <ImageContainer>
        <div>
          <img src={Image} />
        </div>
      </ImageContainer>
    </Container>
  );
};
export default CarbonEmissionChart;
