import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import {
  Select,
  MenuItem,
  BackButton,
  StepTitle,
  Button,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useNavigate } from "react-router-dom";
import { useCompanyContext } from "../../../context/CompanyContext";
import { apiIBGE } from "../../../../../services/api";
import { IoArrowForwardSharp } from "react-icons/io5";
import CircularProgress from "@mui/material/CircularProgress";
import COChart from "../../COChart";

interface IRegion {
  id: number;
  nome: string;
  sigra: string;
}

interface IState {
  id: number;
  nome: string;
  sigla: string;
  regiao: IRegion;
}

const StateStep = () => {
  const { loading, changeStepScreen } = useCompanyContext();
  const [state, setState] = useState<string>();
  const [states, setStates] = useState<IState[]>();
  const [loadingState, setLoadingState] = useState(true);

  const navigate = useNavigate();

  const handleChange = (event: any): void => {
    setState(event.target.value);
  };

  const handleClickNext = () => {
    if (state) {
      changeStepScreen(1);
    }
  };

  const handleGoBack = (): void => {
    changeStepScreen(-1);
  };

  const getStates = async () => {
    try {
      const response = await apiIBGE.get("api/v1/localidades/estados");

      const { data } = response;
      const dataSort = data.sort((a: IState, b: IState) =>
        a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0
      );
      setStates(dataSort);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setLoadingState(false);
    }, 300);
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <React.Fragment>
      {loadingState ? (
        <div className="min-h-screen flex items-center justify-center w-full">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Container>
          <ResponsibleContainer spacingX spacingY>
            <StepHeader>
              <div>
                <BackButton onClick={() => handleGoBack()} />
                <StepTitle>
                  Em que estado sua empresa está localizada?
                </StepTitle>
              </div>
              <div></div>
            </StepHeader>
          </ResponsibleContainer>

          <ImageContainer />

          <StepForm>
            <FormResponsible>
              <div className="w-full"> </div>
              <LabelContainer>
                <span>Selecione</span>
              </LabelContainer>

              <FormControl
                variant="standard"
                sx={{
                  minWidth: "100%",
                }}
              >
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={state}
                  onChange={(e) => handleChange(e)}
                  label="Age"
                >
                  <MenuItem value={undefined}>
                    {" "}
                    &nbsp;&nbsp;&nbsp; Selecione
                  </MenuItem>
                  {states?.map((d) => (
                    <MenuItem value={d.id}>
                      {" "}
                      &nbsp;&nbsp;&nbsp; {d.nome.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <ButtonContainer>
                <Button styleType="third" onClick={() => handleClickNext()} disabled={!state && true}>
                  Avançar <IoArrowForwardSharp size={20} />
                </Button>
              </ButtonContainer>
            </FormResponsible>
          </StepForm>
        </Container>
      )}
    </React.Fragment>
  );
};

export default StateStep;
