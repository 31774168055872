import { InputLabel } from "@mui/material";
import styled from "styled-components";

const InputLabelCustomized = styled(InputLabel)(props => ({
  '& .MuiInputLabel-shrink': {
    // backgroundColor: 'red',

  },
}))

export default InputLabelCustomized