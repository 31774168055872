import { COLORS, BORDER_COLORS } from "../colors-piechart";
import { IKey } from "../../components/CarbonEmissionChart";

export const returnColor = ({ key }: IKey) => {
  switch (key) {
    case "mobility":
      return COLORS[0];
      break;
    case "gas":
      return COLORS[1];
      break;
    case "waste":
      return COLORS[2];
      break;
    case "energy":
      return COLORS[3];
      break;
    case "home":
      return COLORS[4];
      break;
    case "consumption":
      return COLORS[5];
      break;
    case "publicMobility":
      return COLORS[6];
      break;
    default:
      return COLORS[1];
      break;
  }
};

export const returnBorderColor = ({ key }: IKey) => {
  switch (key) {
    case "mobility":
      return BORDER_COLORS[0];
      break;
    case "gas":
      return BORDER_COLORS[1];
      break;
    case "waste":
      return BORDER_COLORS[2];
      break;
    case "energy":
      return BORDER_COLORS[3];
      break;
    case "home":
      return BORDER_COLORS[4];
      break;
    case "consumption":
      return BORDER_COLORS[5];
      break;
    case "publicMobility":
      return BORDER_COLORS[6];
      break;
    default:
      return BORDER_COLORS[1];
      break;
  }
};
