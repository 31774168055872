import { useState } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  Switch,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";

import BackgroundImage from "../../../../../assets/img/PF04.png";

const UsePublicTransportStep = () => {
  const {
    showFuelStep,
    setShowPublicTransportStep,
    changeStepScreen
  } = usePersonContext();
  const [useTransportPublic, setUseTransportPublic] = useState(false)

  const handleClickNext = (): void => {
    const showPublicTransport = useTransportPublic ? true : false
    setShowPublicTransportStep(showPublicTransport)
    changeStepScreen(showPublicTransport ? 1 : 3);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(showFuelStep ? -1 : -2)} />
            <StepTitle>
              VOCÊ UTILIZA TRANSPORTE COLETIVO NO SEU DIA A DIA?
            </StepTitle>
          </div>
          <div>
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Selecione</span>
          </LabelContainer>

          <div className="w-full flex justify-center gap-8">
            <span className="text-xl">Não</span>
            <Switch  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setUseTransportPublic(checked)} />
            <span className="text-xl">Sim</span>
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default UsePublicTransportStep;
