import * as React from "react";
import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SliderField,
  Switch,
  FieldSwitcher,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import Image from "../../../../../assets/img/PJ06.png";
import COChart from "../../COChart";
import { addBrlCurrencyToNumber } from "../../../../../utils";

const FuelStep = () => {
  const {
    changeStepScreen,
    regularGasoline,
    setRegularGasoline,
    setEthanol,
    ethanol,
    setDiesel,
    diesel,
    setGnv,
    gnv,
  } = useCompanyContext();
  const [isDigitable, setIsDigitable] = useState(false);

  const slideProps = {
    min: 0,
    max: 2500,
    isDigitable,
    suffix: "R$",
    placeholder: "0,00",
  };

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  useEffect(() => {
    setRegularGasoline(0);
    setEthanol(0);
    setDiesel(0);
    setGnv(0);
  }, [isDigitable]);

  return (
    <Container backgroundColor="#B0E2EC">
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              EM MÉDIA, QUAL O GASTO DA SUA EMPRESA COM COMBUSTÍVEL POR MÊS?
            </StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
      <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Selecione ou digite os valores</span>
          </LabelContainer>

          <div className="w-11/12">
            <SliderField
              label="Gasolina Comum"
              mask="currency"
              showCounter
              showFooter
              alignLabel="right"
              onChange={(value: number | number[]) => setRegularGasoline(value)}
              counterLabel={addBrlCurrencyToNumber(regularGasoline)}
              {...slideProps}
            />
          </div>
          <div className="w-11/12">
            <SliderField
              label="Etanol"
              mask="currency"
              showCounter
              showFooter
              alignLabel="right"
              counterLabel={addBrlCurrencyToNumber(ethanol)}
              onChange={(value: number | number[]) => setEthanol(value)}
              {...slideProps}
            />
          </div>
          <div className="w-11/12">
            <SliderField
              label="Diesel"
              mask="currency"
              showCounter
              showFooter
              alignLabel="right"
              counterLabel={addBrlCurrencyToNumber(diesel)}
              onChange={(value: number | number[]) => setDiesel(value)}
              {...slideProps}
            />
          </div>
          <div className="w-11/12">
            <SliderField
              label="GNV"
              mask="currency"
              showCounter
              showFooter
              alignLabel="right"
              counterLabel={addBrlCurrencyToNumber(gnv)}
              onChange={(value: number | number[]) => setGnv(value)}
              {...slideProps}
            />
          </div>

          <FieldSwitcher
            onChange={(checked: boolean) => setIsDigitable(checked)}
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default FuelStep;
