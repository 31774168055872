import constate from "constate";
import { useState, useEffect } from "react";
import { Idata } from "../../../components/CarbonEmissionChart";
import { companyFactors } from "../../../utils/factors";

const CompanyContext = () => {
  const factors = companyFactors;
  const lastYear = new Date().getFullYear() - 1;

  const initialDataChart: Idata[] = [{ key: 'test', name: "Group A", value: 0 }];

  const [loading, setLoading] = useState(false);
  const [stepScreen, setStepScreen] = useState<number>(0);

  const [privateMobilityEmission, setPrivateMobilityEmission] = useState(0);
  const [energyEmission, setEnergyEmission] = useState(0);
  const [
    consumptionAndWasteGeneratorEmission,
    setConsumptionAndWasteGeneratorEmission,
  ] = useState(0);
  const [dataCarbonEmission, setDataCarbonEmission] =
    useState<Idata[]>(initialDataChart);
  const [emissionCarbon, setEmissionCarbon] = useState(0);

  // Fuel Step
  const [regularGasoline, setRegularGasoline] = useState<any | number>(0);
  const [ethanol, setEthanol] = useState<any | number>(0);
  const [diesel, setDiesel] = useState<any | number>(0);
  const [gnv, setGnv] = useState<any | number>(0);

  const [spendingTransportApps, setSpendingTransportApps] = useState<
    number | any
  >(0);

  const [nationalTrip, setNationalTrip] = useState(0);
  const [internationalTrip, setInternationalTrip] = useState(0);

  // Energy
  const [showRenewebleSourceEnergyStep, setShowRenewebleSourceEnergyStep] =
    useState(false);
  const [eletricityConsumption, setEletricityConsumption] = useState<
    any | number
  >(0);
  const [gasConsumption, setGasConsumption] = useState<any | number>(0);
  const [airCondicionaireSplitQuantity, setAirCondicionaireSplitQuantity] =
    useState(0);
  const [refillSplitAirCondicionaire, setRefillSplitAirCondicionaire] =
    useState<1 | 3>(1);
  const [airCondicionaireCentralQuantity, setAirCondicionaireCentralQuantity] =
    useState(0);
  const [refillCentralAirCondicionaire, setRefillCentralAirCondicionaire] =
    useState<1 | 3>(1);
  const [fuelConsumptionByPowerGenerator, setFuelConsumptionByPowerGenerator] =
    useState(0);
  const [kwhSourceRenovable, setKwhSourceRenovable] = useState(0);

  // Waste
  const [wasteKg, setWasteKg] = useState<any | number>(0);
  const [recyclingPercentage, setRecyclingPercentage] = useState<any | number>(
    0
  );

  const calculateEmissionByPrivateMobility = () => {
    let result = 0;
    let regularGasolineEmission =
      regularGasoline * factors.fuel.regularGasoline;
    let ethanolEmission = ethanol * factors.fuel.ethanol;
    let dieselEmission = diesel * factors.fuel.diesel;
    let gnvEmission = gnv * factors.fuel.gnv;

    let nationalTripEmission = nationalTrip * factors.airplaneTrip.national;
    let internationalTripEmission =
      internationalTrip * factors.airplaneTrip.international;
    let spendingTransportAppEmission = spendingTransportApps * factors.spendingTransport;

    result =
      (regularGasolineEmission +
        ethanolEmission +
        dieselEmission +
        gnvEmission +
        spendingTransportAppEmission +
        nationalTripEmission +
        internationalTripEmission) /
      1000;

    dataCarbonEmission[0] = {
      key: 'mobility',
      name: "Mobilidade",
      value: result,
    };
    setDataCarbonEmission(dataCarbonEmission);
    setPrivateMobilityEmission(result);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const calculateEmissionByEnergy = () => {
    let result = 0;
    let eletricityEmission = eletricityConsumption * factors.eletricity;
    let gasEmission = gasConsumption * factors.gas;
    let splitEmission =
      refillSplitAirCondicionaire === 1
        ? airCondicionaireSplitQuantity * factors.airCondicionaire.oneRefill
        : airCondicionaireSplitQuantity * factors.airCondicionaire.threeRefill;
    let centralEmission =
      refillCentralAirCondicionaire === 1
        ? airCondicionaireCentralQuantity * factors.airCondicionaire.oneRefill
        : airCondicionaireCentralQuantity *
          factors.airCondicionaire.threeRefill;
    let powerGeneratorEmission =
      fuelConsumptionByPowerGenerator * factors.powerGenerator;
    let kwhSourceRenovableReducion =
      kwhSourceRenovable * factors.kwhSourceRenovable;

    result =
      (eletricityEmission +
        gasEmission +
        splitEmission +
        centralEmission +
        powerGeneratorEmission +
        kwhSourceRenovableReducion) /
      1000;

    dataCarbonEmission[1] = {
      key: 'energy',
      name: "Energia",
      value: result,
    };
    setDataCarbonEmission(dataCarbonEmission);
    setEnergyEmission(result);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const calculateEmissionByConsumptionAndWasteGenerator = () => {

    let percentage = 1 - (recyclingPercentage / 100)
    let result = (wasteKg * percentage) * factors.consumptionAndWasteGeneratorEmission
   
    dataCarbonEmission[2] = {
      key: 'waste',
      name: "Consumo e geração de resíduos",
      value: result,
    };
    setDataCarbonEmission(dataCarbonEmission);
    setConsumptionAndWasteGeneratorEmission(result);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const calculateCarbonEmissionTotal = () => {
    setEmissionCarbon(
      privateMobilityEmission +
        energyEmission +
        consumptionAndWasteGeneratorEmission
    );
  };

  const changeStepScreen = (value: number): void => {
    if (value > 0) {
      setStepScreen(stepScreen + value);
    }
    if (value < 0) {
      setStepScreen(stepScreen + value);
    }
  };

  useEffect(() => {
    setLoading(true);
    calculateEmissionByPrivateMobility();
  }, [regularGasoline, ethanol, diesel, gnv, spendingTransportApps, nationalTrip, internationalTrip]);

  useEffect(() => {
    setLoading(true);
    calculateEmissionByEnergy();
  }, [
    eletricityConsumption,
    gasConsumption,
    airCondicionaireSplitQuantity,
    airCondicionaireCentralQuantity,
    refillSplitAirCondicionaire,
    refillCentralAirCondicionaire,
    fuelConsumptionByPowerGenerator,
    kwhSourceRenovable,
  ]);

  useEffect(() => {
    setLoading(true);
    calculateEmissionByConsumptionAndWasteGenerator();
  }, [wasteKg, recyclingPercentage]);

  useEffect(() => {
    calculateCarbonEmissionTotal();
  }, [
    privateMobilityEmission,
    energyEmission,
    consumptionAndWasteGeneratorEmission,
  ]);

  return {
    lastYear,

    stepScreen,
    setStepScreen,
    loading,
    changeStepScreen,

    regularGasoline,
    setRegularGasoline,
    ethanol,
    setEthanol,
    diesel,
    setDiesel,
    gnv,
    setGnv,
    spendingTransportApps,
    setSpendingTransportApps,
    setNationalTrip,
    setInternationalTrip,

    showRenewebleSourceEnergyStep,
    setShowRenewebleSourceEnergyStep,
    setEletricityConsumption,
    setGasConsumption,
    setAirCondicionaireSplitQuantity,
    setRefillSplitAirCondicionaire,
    setAirCondicionaireCentralQuantity,
    setRefillCentralAirCondicionaire,
    setFuelConsumptionByPowerGenerator,
    kwhSourceRenovable,
    setKwhSourceRenovable,

    setWasteKg,
    recyclingPercentage,
    setRecyclingPercentage,

    dataCarbonEmission,
    emissionCarbon,
  };
};
const [CompanyProvider, useCompanyContext] = constate(CompanyContext);
export { CompanyProvider, useCompanyContext };
