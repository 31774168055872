import styled from "styled-components";
import { IoGridSharp } from "react-icons/io5";
import LogoImage from "../../assets/logo/logo.png"
import theme from "../../config/theme/light";

const Container = styled.div`
  display: flex;
  gap: 10px;
  padding: ${({theme}) => theme.matrics.padding / 2}px ${({theme}) => theme.matrics.padding}px;
  align-items: center;
`
const Image = styled.img`
  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    width: 50%;
    margin: auto;
  }
`
Image.defaultProps = {theme:theme}


const Logo = () => {
  return (
    <Container>
      <Image src={LogoImage} />
    </Container>
  )
}

export default Logo