import * as React from "react";
import { SliderContainer, DotContainer, Dot } from "./styles";
import { usePersonContext } from "../../../context/personContext";
import { CounterField } from "../../../../../components";

interface ItemsPurchasedStepSliderProps {
  dotSelected: number
  setDotSelected: React.Dispatch<React.SetStateAction<number>>
}

const ItemsPurchasedStepSlider = ({dotSelected, setDotSelected}:ItemsPurchasedStepSliderProps) => {
  const {
    setCoats,
    setSockes,
    setPajamas,
    setTShirts,
    setTablests,
    setNotebooks,
    setSmartphones,
    setComputer,
  } = usePersonContext();

  return (
    <SliderContainer>
      {dotSelected === 0 && (
        <>
          <CounterField
            label="Casacos, jaquetas, vestidos, camisas"
            onChange={(newValue: number) => setCoats(newValue)}
          />
          <CounterField
            label="Meias, roupas íntimas, roupas de banho"
            onChange={(newValue: number) => setSockes(newValue)}
          />
          <CounterField
            label="Pijamas, gravatas, cachecóis"
            onChange={(newValue: number) => setPajamas(newValue)}
          />
        </>
      )}

      {dotSelected == 1 && (
        <>
          <CounterField
            label="Camisetas, calças, saias, shorts, bermudas e roupas de treino"
            onChange={(newValue: number) => setTShirts(newValue)}
          />
          <CounterField
            label="Tablets, dispositivos com comando de fala"
            onChange={(newValue: number) => setTablests(newValue)}
          />
          <CounterField
            label="Notebooks"
            onChange={(newValue: number) => setNotebooks(newValue)}
          />
        </>
      )}

      {dotSelected == 2 && (
        <>
          <CounterField
            label="Smartphones, Celulares"
            onChange={(newValue: number) => setSmartphones(newValue)}
          />
          <CounterField
            label="Computadores, monitores e TV's"
            onChange={(newValue: number) => setComputer(newValue)}
          />
        </>
      )}

    </SliderContainer>
  );
};

export default ItemsPurchasedStepSlider;
