import constate from "constate";
import { useState, useEffect } from "react";
import { Idata } from "../../../components/CarbonEmissionChart";
import { GasTypes } from "../../../utils/enums";
import { fisicPersonFactos } from "../../../utils/factors";

const publicTransports = [
  {
    key: 0,
    name: "Metrô",
    factor: "metro",
    status: false,
    minutes: 0,
  },
  {
    key: 1,
    name: "Trem",
    factor: "subway",
    status: false,
    minutes: 0,
  },
  {
    key: 2,
    name: "ônibus",
    factor: "bus",
    status: false,
    minutes: 0,
  },
  {
    key: 3,
    name: "van",
    factor: "van",
    status: false,
    minutes: 0,
  },
  {
    key: 4,
    name: "Balsa ou Catamarã",
    factor: "boat",
    status: false,
    minutes: 0,
  },
];

const PersonContext = () => {
  const factors = fisicPersonFactos;

  const lastYear = new Date().getFullYear() - 1;

  const initialDataChart: Idata[] = [
    { key: "test", name: "Group A", value: 0 },
  ];

  const [loading, setLoading] = useState(false);
  const [stepScreen, setStepScreen] = useState<number>(0);
  const [dataCarbonEmission, setDataCarbonEmission] =
    useState<Idata[]>(initialDataChart);

  const [privateMobilityEmission, setPrivateMobilityEmission] = useState(0);
  const [publicMobilityEmission, setPublicMobilyEmission] = useState(0);
  const [homeEmission, setHomeEmission] = useState(0);
  const [consumptionEmission, setConsumptionEmission] = useState(0);
  const [foodEmission, setFoodEmission] = useState(0);
  const [wasteEmission, setWasteEmission] = useState(0);
  const [emissionCarbon, setEmissionCarbon] = useState(0);

  // Fuel Step
  const [showFuelStep, setShowFuelStep] = useState(false);
  const [regularGasoline, setRegularGasoline] = useState<any | number>(0);
  const [ethanol, setEthanol] = useState<any | number>(0);
  const [diesel, setDiesel] = useState<any | number>(0);
  const [gnv, setGnv] = useState<any | number>(0);
  const [biodiesel, setBiodiesel] = useState<any | number>(0);

  // AirTravel
  const [nationalTrip, setNationalTrip] = useState(0);
  const [americaTrip, setAmericaTrip] = useState(0);
  const [europeTrip, setEuropeTrip] = useState(0);
  const [asiaOceaniaTrip, setAsiaOceaniaTrip] = useState(0);
  const [africaTrip, setAfricaTrip] = useState(0);

  // Public transport
  const [showPublicTransportStep, setShowPublicTransportStep] = useState(false);
  const [minutesInTransportPublic, setMinutesInTransportPublic] = useState<
    any | number
  >(0);
  const [publicTransportsKey, setPublicTransportsKey] = useState(0);

  // Home
  const [showGasStep, setShowGasStep] = useState(false);
  const [peopleQuantity, setPeopleQuantity] = useState(1);
  const [eletricityConsumption, setEletricityConsumption] = useState<
    any | number
  >(0);
  const [gasTypeSelected, setGasTypeSelected] = useState<GasTypes>(0);
  const [gasConsumption, setGasConsumption] = useState<any | number>(0);

  // Consumption Step
  const [coats, setCoats] = useState(0);
  const [socks, setSockes] = useState(0);
  const [pajamas, setPajamas] = useState(0);
  const [tShirts, setTShirts] = useState(0);
  const [tablets, setTablests] = useState(0);
  const [notebooks, setNotebooks] = useState(0);
  const [smartphones, setSmartphones] = useState(0);
  const [computer, setComputer] = useState(0);

  // Food consumption
  const [beef, setBeef] = useState(0);
  const [chicken, setChicken] = useState(0);
  const [pork, setPork] = useState(0);
  const [milk, setMilk] = useState(0);
  const [eggs, setEggs] = useState(0);
  const [riceBean, setRiceBean] = useState(0);

  // Waste
  const [barbageBag, setBarbageBag] = useState<any | number>(0);
  const [recyclingPercentage, setRecyclingPercentage] = useState<number | any>(0);
  const [compostPercentage, setCompostPercentage] = useState<number | any>(0);
  const [barbageTruckPercentage, setBarbageTruckPercentage] = useState<
    number | any
  >(0);

  const changeStepScreen = (value: number): void => {
    if (value > 0) {
      setStepScreen(stepScreen + value);
    }
    if (value < 0) {
      setStepScreen(stepScreen + value);
    }
  };

  const calculateEmissionByPrivateMobility = () => {
    let result = 0;
    let regularGasolineEmission =
      regularGasoline * factors.fuel.regularGasoline;
    let ethanolEmission = ethanol * factors.fuel.ethanol;
    let dieselEmission = diesel * factors.fuel.diesel;
    let gnvEmission = gnv * factors.fuel.gnv;
    // let biodieselEmission = biodiesel * factors.fuel.biodiesel

    let nationalTripEmission = nationalTrip * factors.airplaneTrip.national;
    let americaTripEmission = americaTrip * factors.airplaneTrip.america;
    let europeTripEmission = europeTrip * factors.airplaneTrip.europe;
    let asiaOceaniaTripEmission =
      asiaOceaniaTrip * factors.airplaneTrip.asiaOceania;
    let africaTripEmission = africaTrip * factors.airplaneTrip.africa;

    result =
      (regularGasolineEmission +
        ethanolEmission +
        dieselEmission +
        gnvEmission +
        nationalTripEmission +
        americaTripEmission +
        europeTripEmission +
        asiaOceaniaTripEmission +
        africaTripEmission) /
      1000;

    dataCarbonEmission[0] = {
      key: "mobility",
      name: "Mobilidade privada",
      value: result,
    };

    setDataCarbonEmission(dataCarbonEmission);
    setPrivateMobilityEmission(result);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const calculateEmissionByPublicTransport = () => {
    let result = 0;
    let selectedTransports = publicTransports.filter((d) => d.status);

    selectedTransports.map((d) => {
      if (d.factor === "metro") {
        result =
          result + factors.publicTransport.metro * minutesInTransportPublic;
      } else if (d.factor === "subway") {
        result =
          result + factors.publicTransport.subway * minutesInTransportPublic;
      } else if (d.factor === "bus") {
        result =
          result + factors.publicTransport.bus * minutesInTransportPublic;
      } else if (d.factor === "van") {
        result =
          result + factors.publicTransport.van * minutesInTransportPublic;
      } else {
        result =
          result + factors.publicTransport.boat * minutesInTransportPublic;
      }
    });

    result = result / 1000;

    dataCarbonEmission[1] = {
      key: "publicMobility",
      name: "Mobilidade pública",
      value: result,
    };
    setDataCarbonEmission(dataCarbonEmission);
    setPublicMobilyEmission(result);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const calculateEmissionByHome = () => {
    let result = 0;
    let eletricityEmission = eletricityConsumption * factors.eletricity;
    let gasEmission = 0;

    switch (gasTypeSelected) {
      case 0:
        gasEmission = 0;
        break;
      case 1:
        gasEmission = gasConsumption * factors.gas.glp;
        break;
      case 2:
        gasEmission = gasConsumption * factors.gas.piped;
        break;
    }
    result = ((eletricityEmission + gasEmission) * peopleQuantity) / 1000;

    dataCarbonEmission[2] = {
      key: "home",
      name: "Moradia",
      value: result,
    };
    setDataCarbonEmission(dataCarbonEmission);
    setHomeEmission(result);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const calculateEmissionByConsuption = () => {
    let result = 0;
    let coatsEmission = coats * factors.purchasedItems.coats;
    let socksEmission = socks * factors.purchasedItems.socks;
    let pajamasEmission = pajamas * factors.purchasedItems.pajamas;
    let tShirtsEmission = tShirts * factors.purchasedItems.tShirts;
    let tabletsEmission = tablets * factors.purchasedItems.tablets;
    let notebooksEmission = notebooks * factors.purchasedItems.notebooks;
    let smartphonesEmission = smartphones * factors.purchasedItems.smartphones;
    let computerEmission = computer * factors.purchasedItems.computer;

    result =
      (coatsEmission +
        socksEmission +
        pajamasEmission +
        tShirtsEmission +
        tabletsEmission +
        notebooksEmission +
        smartphonesEmission +
        computerEmission) /
      1000;

    dataCarbonEmission[3] = {
      key: "consumption",
      name: "Consumo",
      value: result,
    };
    setDataCarbonEmission(dataCarbonEmission);
    setConsumptionEmission(result);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const calculateCarbonEmissionByFoods = () => {
    let result = 0;
    let beefEmission = beef * factors.food.beef;
    let chickenEmission = chicken * factors.food.chicken;
    let porkEmission = pork * factors.food.pork;
    let milkEmission = milk * factors.food.milk;
    let eggsEmission = eggs * factors.food.eggs;
    let riceBeanEmission = riceBean * factors.food.riceBean;

    result =
      beefEmission +
      chickenEmission +
      porkEmission +
      milkEmission +
      eggsEmission +
      riceBeanEmission;

    dataCarbonEmission[4] = {
      key: "food",
      name: "Alimentação",
      value: result,
    };
    setDataCarbonEmission(dataCarbonEmission);
    setFoodEmission(result);

    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const calculateWasteEmission = () => {
    const litersPerBarbageBag = 6
    const descont = 1 - (recyclingPercentage + compostPercentage) / 100
    let result = ((barbageBag * litersPerBarbageBag) * factors.waste);
    result = result * descont 

    dataCarbonEmission[5] = {
      key: "waste",
      name: "Resíduos",
      value: result,
    };
    setDataCarbonEmission(dataCarbonEmission);

    setWasteEmission(result);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const calculateCarbonEmissionTotal = () => {
    setEmissionCarbon(
      privateMobilityEmission +
        publicMobilityEmission +
        homeEmission +
        consumptionEmission +
        foodEmission +
        wasteEmission
    );
  };

  useEffect(() => {
    setLoading(true);
    calculateEmissionByPrivateMobility();
  }, [
    regularGasoline,
    ethanol,
    diesel,
    gnv,
    biodiesel,
    nationalTrip,
    americaTrip,
    europeTrip,
    asiaOceaniaTrip,
    africaTrip,
  ]);

  useEffect(() => {
    setLoading(true);
    calculateEmissionByPublicTransport();
  }, [minutesInTransportPublic, publicTransportsKey]);

  useEffect(() => {
    setLoading(true);
    calculateEmissionByHome();
  }, [peopleQuantity, eletricityConsumption, gasConsumption, gasTypeSelected]);

  useEffect(() => {
    setLoading(true);
    calculateEmissionByConsuption();
  }, [
    coats,
    socks,
    pajamas,
    tShirts,
    tablets,
    notebooks,
    smartphones,
    computer,
  ]);

  useEffect(() => {
    setLoading(true);
    calculateCarbonEmissionByFoods();
  }, [beef, chicken, pork, milk, eggs, riceBean]);

  useEffect(() => {
    setLoading(true);
    calculateWasteEmission();
  }, [barbageBag, recyclingPercentage, compostPercentage, barbageTruckPercentage]);

  useEffect(() => {
    calculateCarbonEmissionTotal();
  }, [
    privateMobilityEmission,
    publicMobilityEmission,
    homeEmission,
    consumptionEmission,
    foodEmission,
    wasteEmission,
  ]);

  return {
    lastYear,

    stepScreen,
    setStepScreen,
    loading,
    changeStepScreen,

    dataCarbonEmission,
    emissionCarbon,

    showFuelStep,
    setShowFuelStep,
    regularGasoline,
    setRegularGasoline,
    ethanol,
    setEthanol,
    diesel,
    setDiesel,
    gnv,
    setGnv,
    biodiesel,
    setBiodiesel,

    setAmericaTrip,
    setNationalTrip,
    setEuropeTrip,
    setAsiaOceaniaTrip,
    setAfricaTrip,

    showPublicTransportStep,
    setShowPublicTransportStep,
    publicTransports,
    minutesInTransportPublic,
    setMinutesInTransportPublic,
    publicTransportsKey,
    setPublicTransportsKey,

    eletricityConsumption,
    setEletricityConsumption,
    showGasStep,
    setShowGasStep,
    setGasTypeSelected,
    gasConsumption,
    setGasConsumption,

    setCoats,
    setSockes,
    setPajamas,
    setTShirts,
    setTablests,
    setNotebooks,
    setSmartphones,
    setComputer,

    setBeef,
    setChicken,
    setPork,
    setMilk,
    setEggs,
    setRiceBean,

    peopleQuantity,
    setPeopleQuantity,

    setBarbageBag,

    recyclingPercentage,
    setRecyclingPercentage,
    compostPercentage,
    setCompostPercentage,
    barbageTruckPercentage,
    setBarbageTruckPercentage,
  };
};
const [PersonProvider, usePersonContext] = constate(PersonContext);
export { PersonProvider, usePersonContext };
