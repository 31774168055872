import styled from "styled-components";

export const IconCustomized = styled.button`
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: white;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: #121212;
  font-size: .6rem;
  font-weight: 900;
`