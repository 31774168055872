import * as React from "react";
import { styled } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";

const MenuItemCustomized = styled(MenuItem)((props) => ({
  // backgroundColor: "red",
  color: "#616161",

  "& 	.MuiMenuItem-root": {
    // backgroundColor: "red",
  },
  "& 	.Mui-focusVisible": {
    // backgroundColor: "red",
  },
}));

export default MenuItemCustomized;
