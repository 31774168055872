import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  & > div {
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div > img {
    display: inline-block;
    width: 40px
  }
`