import * as React from 'react';
import styled, { css } from 'styled-components';
import arrowRight from "../../assets/img/arrowRight.png"

type IButton = {
  styleType?: 'primary' | 'secundary' | 'third',
  disabled?: boolean
}

const Button = styled.button<IButton>`
  text-align: center;
  color: ${({ theme }) => theme.colors.white} ;
  background-color:  ${({ theme }) => theme.colors.primaryBackgroundColorButton} ;
  padding: 8px 24px;
  border: none;
  font-size: 14px;
  transition: 0.2s;
  display: flex;
  align-items: center;
  font-weight: 600;
  min-width: 200px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;

  ${props => props.styleType === "secundary" && css`
    color: ${({ theme }) => theme.colors.white} ;
    background-color: ${({ theme }) => theme.colors.secundaryBackgroundColorButton} ;
  `}

  ${props => props.styleType === "third" && css`
    justify-content: space-between;
    text-align: left;
    color: ${({ theme }) => theme.colors.gray1} ;
    background-color: ${({ theme }) =>theme.colors.thirthBackgroundColorButton}} ;
    box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.84);
    padding: 12px 24px;
  `}

  &:hover {
    transform: scale(1.03);
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    opacity: .7;
    transform: scale(1);
    cursor: no-drop
  }
`

export default Button

