import { useNavigate } from "react-router-dom";
import { Header, Title } from "../../../../components/styles";
import { ResponsibleContainer } from "../styles";
import { ContentContainer, Container } from "./styles";

import { Button } from "../../../../components";
import { ButtonsContainer } from "../../styles";

const CalculateSection = () => {

  const navigate = useNavigate();
  const onClickButton = (route: string) => {
    navigate(route);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY={false}>
        <ContentContainer>
          <Header>
            <Title>Calcule Agora</Title>
          </Header>
          <ButtonsContainer>
            <Button
              styleType="primary"
              onClick={() => onClickButton("/calculadora")}
            >
              PARA VOCÊ
            </Button>
            <Button
              onClick={() => onClickButton("/calculadora-para-empresas")}
              styleType="secundary"
            >
              PARA SUA EMPRESA
            </Button>
          </ButtonsContainer>
        </ContentContainer>
      </ResponsibleContainer>
    </Container>
  );
};
export default CalculateSection;
