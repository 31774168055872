import { useNavigate } from "react-router-dom";
import { Button, Logo } from "../../../../components";
import {
  GradientContainer,
  Content,
  SubTitle,
  Title,
  TextContainer,
  Text,
  ButtonsContainer,
  Navbar,
} from "../../styles";
import { Container } from "./styles";

const HeaderHome = () => {
  const navigate = useNavigate();
  const onClickButton = (route: string) => {
    navigate(route);
  };

  return (
    <GradientContainer>
        <Container>
          <Navbar>
            <Logo />
          </Navbar>
          <Content className="">
            <SubTitle>QUAL O TAMANHO DA SUA</SubTitle>
            <Title>PEGADA ECOLÓGICA?</Title>

            <TextContainer>
              <Text>Faça sua parte por um futuro mais sustentável</Text>
              <Text>
                Use nossa calculadora e descubra as emissões de carbono das suas
                atividades
              </Text>
            </TextContainer>

            <ButtonsContainer>
              <Button
                styleType="primary"
                onClick={() => onClickButton("/calculadora")}
              >
                PARA VOCÊ
              </Button>
              <Button onClick={() => onClickButton("/calculadora-para-empresas")} styleType="secundary">
                PARA SUA EMPRESA
              </Button>
            </ButtonsContainer>
          </Content>
        </Container>
      </GradientContainer>
  )
}

export default HeaderHome