import {
  BackButton,
  StepTitle,
  Button,
  CounterField,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";
import BackgroundImage from "../../../../../assets/img/PF06.png";

const PublicTransport = () => {
  const {
    showPublicTransportStep,
    lastYear,
    setAmericaTrip,
    setNationalTrip,
    setEuropeTrip,
    setAsiaOceaniaTrip,
    setAfricaTrip,

    changeStepScreen,
  } = usePersonContext();

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(showPublicTransportStep ? -1 : -3)} />
            <StepTitle>
            QUANTAS VIAGENS DE AVIÃO VOCÊ FEZ NO ANO {lastYear}
            </StepTitle>
          </div>
          <div>
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Caso não tenha feito, avance para a próxima pergunta</span>
          </LabelContainer>

          <div className="w-full">
            <CounterField
              label="Nacional"
              onChange={(value: number) => setNationalTrip(value)}
            />
          </div>
          <div className="w-full">
            <CounterField
              label="América"
              onChange={(value: number) => setAmericaTrip(value)}
            />
          </div>
          <div className="w-full">
            <CounterField
              label="Europa"
              onChange={(value: number) => setEuropeTrip(value)}
            />
          </div>
          <div className="w-full">
            <CounterField
              label="Ásia/Oceania"
              onChange={(value: number) => setAsiaOceaniaTrip(value)}
            />
          </div>
          <div className="w-full">
            <CounterField
              label="África"
              onChange={(value: number) => setAfricaTrip(value)}
            />
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default PublicTransport;
