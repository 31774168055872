export const COLORS = [
  "#EF9E11",
  "#06818b",
  "#328D3A",
  "#BBCE33",
  "#e75821",
  "#EA7746",
  "#f56dff"
];

export const BORDER_COLORS = [
  "#E47719",
  "#068100",
  "#C2CD31",
  "#BBCE00",
  "#D64A21",
  "#e45521",
  "#c957d1"
];
