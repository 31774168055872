import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SliderWithCounter,
  Switch,
  InputNumber,
  FieldWithLabelAndIcon,
  FieldSwitcher,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import Image from "../../../../../assets/img/PJ07.png";
import Icon from "../../../../../assets/icons/PJ07.png";
import COChart from "../../COChart";

const SpendingTransportAppsStep = () => {
  const { spendingTransportApps, setSpendingTransportApps, changeStepScreen } = useCompanyContext();
  const [isDigitable, setIsDigitable] = useState(false);

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  useEffect(() => {
    setSpendingTransportApps(0);
  }, [isDigitable]);

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>SOBRE O DESLOCAMENTO DE FUNCIONÁRIOS</StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <FieldWithLabelAndIcon
            inputType={isDigitable ? "inputNumber" : undefined}
            mask="currency"
            icon={Icon}
            label="Em média, qual o gasto mensal com táxi e aplicativos de transporte?"
            onChange={(value: number | number[]) =>
              setSpendingTransportApps(value)
            }
            customLabel={<span>R$ {spendingTransportApps},00</span>}
            placeholder="R$ 0,00"
            max={1000}
          />
          <FieldSwitcher
            onChange={(checked: boolean) => setIsDigitable(checked)}
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default SpendingTransportAppsStep;
