import styled, { css } from "styled-components";

export interface InputContainerProps {
  inputIconAlign?: 'left' | 'right'
  fontSize?: 'small' | 'medium' | 'big'
}

export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;
  position: relative;
  border-bottom: 2px solid ${({ theme }) => theme.colors.white};

  input {
    border: none;
    font-size: ${({fontSize}) => fontSize  === 'small' ? 0.8 : fontSize === 'big' ? 1.7 : 1}rem;
  }

  input:focus {
    border:none
  }

  & > div.inputIcon {
    position: absolute;
    top: 6px;
    left: 0px;

    ${({inputIconAlign}) => inputIconAlign === 'right' && css`
      left: auto;
      right: 0px;
    `}
  }
`