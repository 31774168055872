import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SwitchField,
  IconSwitch,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";

import BackgroundImage from "../../../../../assets/img/PF04.png";
import Metro from "../../../../../assets/icons/PF04/metro.png";
import Subway from "../../../../../assets/icons/PF04/trem.png";
import Bus from "../../../../../assets/icons/PF04/onibus.png";
import Van from "../../../../../assets/icons/PF04/caminhao.png";
import Ferry from "../../../../../assets/icons/PF04/barco.png";

const PublicTransport = () => {
  const [disabled, setDisabled] = useState(true)

  const {
    changeStepScreen,
    publicTransports,
    publicTransportsKey,
    setPublicTransportsKey,
  } = usePersonContext();

  const changeStatus = (key: number, checked: boolean) => {
    if (!checked) {
      publicTransports[key].status = checked;
      publicTransports[key].minutes = 0;
    } else {
      publicTransports[key].status = checked;
    }
    setPublicTransportsKey(publicTransportsKey + 1);
  };

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  useEffect(() => {
    const someTrue = publicTransports.some(d => d.status)
    setDisabled(someTrue ? false: true)
  }, [publicTransportsKey]);

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              QUAIS OS MEIOS DE TRANSPORTE COLETIVO VOCÊ UTILIZA?
            </StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Selecione as opções</span>
          </LabelContainer>

          <div className="w-8/12">
            <SwitchField
              label={
                <span className="flex items-center">
                  <IconSwitch src={Metro} /> Metrô{" "}
                </span>
              }
              onChange={(checked: boolean) => changeStatus(0, checked)}
            />
          </div>
          <div className="w-8/12">
            <SwitchField
              label={
                <span className="flex items-center">
                  <IconSwitch src={Subway} /> Trem{" "}
                </span>
              }
              onChange={(checked: boolean) => changeStatus(1, checked)}
            />
          </div>
          <div className="w-8/12">
            <SwitchField
              label={
                <span className="flex items-center">
                  <IconSwitch src={Bus} /> Ônibus{" "}
                </span>
              }
              onChange={(checked: boolean) => changeStatus(2, checked)}
            />
          </div>
          <div className="w-8/12">
            <SwitchField
              label={
                <span className="flex items-center">
                  <IconSwitch src={Van} /> Van{" "}
                </span>
              }
              onChange={(checked: boolean) => changeStatus(3, checked)}
            />
          </div>
          <div className="w-8/12">
            <SwitchField
              label={
                <span className="flex items-center shrink">
                  <IconSwitch src={Ferry} /> Balsa ou Catamarã{" "}
                </span>
              }
              onChange={(checked: boolean) => changeStatus(4, checked)}
            />
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()} {...{disabled}}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default PublicTransport;
