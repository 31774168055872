import {
  BackButton,
  StepTitle,
  Button,
  CounterField,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";
import BackgroundImage from "../../../../../assets/img/PF14.png";

const ModelStep = () => {
  const {
    loading,
    setBeef,
    setChicken,
    setPork,
    setMilk,
    setEggs,
    setRiceBean,
    changeStepScreen,
  } = usePersonContext();

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
            EM MÉDIA, QUANTAS VEZES POR SEMANA VOCÊ CONSOME OS ALIMENTOS ABAIXO?
            </StepTitle>
          </div>
          <COChart />
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Selecione a quantidade</span>
          </LabelContainer>

          <CounterField label="Carne Bovina" onChange={(newValue:number) => setBeef(newValue)} />
          <CounterField label="Frango" onChange={(newValue:number) => setChicken(newValue)} />
          <CounterField label="Carne de Porco" onChange={(newValue:number) => setPork(newValue)} />
          <CounterField label="Leite e/ou Queijo" onChange={(newValue:number) => setMilk(newValue)} />
          <CounterField label="Ovos" onChange={(newValue:number) => setEggs(newValue)} />
          <CounterField label="Arroz e Feijão" onChange={(newValue:number) => setRiceBean(newValue)}  />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default ModelStep;
