import styled from 'styled-components'

import Background from "../../../../assets/img/00.png";
import Background2 from "../../../../assets/img/002.png";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-size: contain;
  background-image: url(${Background});
  background-position: center bottom;
  background-repeat: no-repeat;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    background-image: url(${Background2});
    background-size: cover;
  }
`;