import {
  BackButton,
  StepTitle,
  Button,
  FieldWithLabelAndIcon,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";

import Image from "../../../../../assets/img/PJ13.png";
import Icon1 from "../../../../../assets/icons/PJ13.png";
import Icon2 from "../../../../../assets/icons/PJ132.png";
import { CustomLabelCounter } from "../../../../../components/FieldWithLabelAndIcon/styles";
import COChart from "../../COChart";

const PowerGeneratorStep = () => {
  const { lastYear, changeStepScreen, setFuelConsumptionByPowerGenerator } =
    useCompanyContext();

  const formResponsibleProps = {
    spacingX: true,
    spacingY: true,
    verticalGap: 5,
    sm: 80,
    md: 70,
    lg: 65,
    xl: 50,
  };

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>SOBRE GERADORES DE ENERGIA E EXTINTORES</StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible {...formResponsibleProps}>
          <div className="hidden w-full md:block"> </div>

          <div className="w-full flex flex-col md:flex-row md:gap-20 items-end h-4/5">
            <FieldWithLabelAndIcon
              icon={Icon1}
              label={`Sua empresa utiliza geradores de energia? Qual foi o gasto médio mensal de combustível no ano de ${lastYear} ?`}
              inputType="inputNumber"
              mask="currency"
              placeholder="0,00"
              suffix={<CustomLabelCounter>R$ </CustomLabelCounter>}
              onChange={(value: number) =>
                setFuelConsumptionByPowerGenerator(value)
              }
              max={5000}
            />

            <FieldWithLabelAndIcon
              icon={Icon2}
              label="Quantos extintores de incêndio sua empresa possui?"
              inputType="inputNumber"
              placeholder="0"
            />
          </div>

          <div className="w-full py-1"> </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default PowerGeneratorStep;
