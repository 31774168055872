import * as React from "react";
import {useEffect} from 'react'
import { CarbonEmissionChart } from "../../../../components";
import { useCompanyContext } from "../../context/CompanyContext";
import { Container } from "../../../../components/CarbonEmissionChart/styles"

const COChart = () => {
  const { loading, emissionCarbon, dataCarbonEmission }  = useCompanyContext()
  useEffect(() => {
  }, [dataCarbonEmission]);
  return (
    <>
      {!loading ? <CarbonEmissionChart data={dataCarbonEmission} counter={emissionCarbon} /> : (
         <CarbonEmissionChart />
      )}
    </>
  );
};
export default COChart;
