
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from '../../pages/Home'
import PersonCalculator from '../../pages/PersonCalculator'
import CompanyCalculator from '../../pages/CompanyCalculator'
import styled from "styled-components"
import StateTest from '../../pages/PersonCalculator/components/Steps/StateTest'

const Container = styled.div`
  background: red;
  position: fixed;
  width: 100%;
  height: 100%;
`

const Teste = () => {
  return <Container>teste</Container>
}

const Navigation = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/calculadora" element={<PersonCalculator/>}></Route>
        <Route path="/calculadora-para-empresas" element={<CompanyCalculator/>}></Route>
        <Route path='*' element={<Home />} />
        <Route path="/teste" element={<StateTest />}></Route>
      </Routes>
    </Router>
  )
}

export default Navigation