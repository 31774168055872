import { useState } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SwitchField,
  SliderField,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";

import Image from "../../../../../assets/img/PJ08.png";
import COChart from "../../COChart";

const TimeSpentStep = () => {
  const { changeStepScreen } = useCompanyContext();
  const [minutes, setMinutes] = useState<number | number[]>(0);
  const [firstField, setFirstField] = useState(false);
  const [secondField, setSecondField] = useState(false);
  const [thirdField, setThirdField] = useState(false);
  const [fourthField, setFourField] = useState(false);

  const fieldsProps = {
    min: 0,
    max: 500,
    showCounter: true,
  };

  const handleSelectSwitch = (
    value: boolean,
    nth: number,
    isExactValue?: boolean
  ) => {
    if (nth === 1) {
      setFirstField(value);
      setSecondField(false);
      setThirdField(false);
      setFourField(false);
      setMinutes(30);
    } else if (nth === 2) {
      setFirstField(false);
      setSecondField(value);
      setThirdField(false);
      setFourField(false);
      setMinutes(50);
    } else if (nth === 3) {
      setFirstField(false);
      setSecondField(false);
      setThirdField(value);
      setFourField(false);
      setMinutes(90);
    } else if (nth === 4) {
      setFirstField(false);
      setSecondField(false);
      setThirdField(false);
      setFourField(value);
      setMinutes(120);
    }

    if (isExactValue) {
      setFirstField(false);
      setSecondField(false);
      setThirdField(false);
      setFourField(false);
    }
  };

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              EM MÉDIA, QUANTO TEMPO SEUS FUNCIONÁRIOS GASTAM PARA CHEGAR NA
              EMPRESA?
            </StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>
            Selecione a opção considerando ida e volta
            </span>
          </LabelContainer>

          <div className="w-9/12">
            <SwitchField
              label="Pouco: até 30 minutos"
              checked={firstField}
              onChange={(value: boolean) => handleSelectSwitch(value, 1)}
            />
          </div>
          <div className="w-9/12">
            <SwitchField
              label="Médio: até 50 minutos"
              checked={secondField}
              onChange={(value: boolean) => handleSelectSwitch(value, 2)}
            />
          </div>
          <div className="w-9/12">
            <SwitchField
              label="Razoável: até 1h e 30 minutos"
              checked={thirdField}
              onChange={(value: boolean) => handleSelectSwitch(value, 3)}
            />
          </div>
          <div className="w-9/12">
            <SwitchField
              label="Muito: mais de 2 horas"
              checked={fourthField}
              onChange={(value: boolean) => handleSelectSwitch(value, 4)}
            />
          </div>

          <div className="w-full">
            <SliderField
              label="Conheço o tempo exato:"
              {...fieldsProps}
              counterLabel={`${minutes} minutos`}
              onChange={(newValue: number | number[]) => {
                setMinutes(newValue);
                handleSelectSwitch(false, 0, true);
              }}
            />
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default TimeSpentStep;
