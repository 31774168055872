import * as React from "react";
import { currencyMask, meterMask, numberMask } from "../../utils";
import { InputContainer, InputContainerProps } from "./styles";

export interface InputNumberProps extends InputContainerProps {
  min?: number
  max?: number
  maxLength?: number
  placeholder?: string;
  suffix?: string | React.ReactNode
  mask?: "currency" | "meter"
  onChange?(param: number): void
}

const InputNumber = ({
  suffix,
  min,
  max,
  maxLength,
  placeholder,
  inputIconAlign,
  fontSize,
  mask,
  onChange,
}: InputNumberProps) => {
  const handleKeyUp = (e: React.FormEvent<HTMLInputElement>) => {
    if (mask === "currency") {
      currencyMask(e, maxLength);

      let value = e.currentTarget.value;

      value = value.replace(".", "");
      value = value.replace(",", ".");

      if (onChange) {
        onChange(parseFloat(!value ? "0" : value));
      }
    } else if (mask === "meter") {
      meterMask(e, maxLength);
    } else {
      numberMask(e, maxLength)
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      if (onChange) {
        onChange(parseInt(!value ? "0" : value));
      }
    }
  };

  return (
    <InputContainer inputIconAlign={inputIconAlign} fontSize={fontSize}>
      <div className="inputIcon"> {suffix && suffix} </div>
      <input {...{ min, max, placeholder }} maxLength={max} onKeyUp={handleKeyUp} />
    </InputContainer>
  );
};

export default InputNumber;
