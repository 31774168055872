import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 15px;
  box-shadow: 24px;
  padding: 25px;
  width: 350px;
  color: ${({theme}) => theme.colors.gray3};

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    width: 400px;
  }
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({theme}) => theme.colors.gray2}
`

export const IconButton = styled.button`
  font-size: 1.5rem;
  font-weight: 600;
`