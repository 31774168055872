import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loadEnvironmentVariables } from "../../loader";
import { HeaderHome, HomeAbout, InformationHome ,CalculateSection } from "./components";

const Home = () => {
  const navigate = useNavigate();
  const onClickButton = (route: string) => {
    navigate(route);
  };

  return (
    <div>
      <HeaderHome />  
      <HomeAbout />
      <InformationHome />
      <CalculateSection />
    </div>
  );
};

export default Home;
