import styled, {css} from "styled-components"
import Vector from "../../../../assets/img/vectorWhite.png";
interface ContainerProps {
  backgroundColor?: string;
  backgroundImage?: boolean;
  textColor?: string;
}

export const Container = styled.div<ContainerProps>`
  margin-top: -1px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.greens.primary};
  background-image: url(${Vector});
  background-repeat: no-repeat;
  background-position: center top;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    background-size: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  min-height: 70vh;
  text-align: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({theme}) => theme.matrics.padding * 1.25}px;
`

export const VectorImage = styled.img`
  position: absolute;
  top: -100px;
  width: 100%;
  margin: auto;
  transform: scale(1.1);
  /* left: 10%; */

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    left: 30%;
    width: 50%;
    margin: auto;
  }
`