import styled from "styled-components";

type FieldsContainerType = {
  spacingY?: number
}

const FieldsContainer = styled.div<FieldsContainerType>`
  width: 100;
  display: flex;
  flex-direction: column;
  row-gap: ${({spacingY}) => spacingY ? spacingY : 13}px;

  @media (min-width: 640px) {
    row-gap: 7px;
  }
`

export default FieldsContainer