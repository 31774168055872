import {
  BackButton,
  StepTitle,
  Button,
  FieldWithLabelAndIcon,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";

import Image from "../../../../../assets/img/PJ1516.png";
import Icon from "../../../../../assets/icons/PJ16.png";
import COChart from "../../COChart";

const RecyclingPercetageStep = () => {
  const { changeStepScreen, recyclingPercentage, setRecyclingPercentage } = useCompanyContext();

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
            QUAL A PORCENTAGEM DESSES RESÍDUOS  DESCARTADOS SÃO DESTINADOS PARA A RECICLAGEM E/OU COMPOSTAGEM?
            </StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <div className="w-full"> </div>

          <FieldWithLabelAndIcon
            icon={Icon}
            label="Selecione o percentual"
            customLabel={<span>{recyclingPercentage} %</span>}
            onChange={(newValue: number | number[]) =>
              setRecyclingPercentage(newValue)
            }
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default RecyclingPercetageStep;
