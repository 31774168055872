export const fisicPersonFactos = {
  fuel: {
    regularGasoline: Number(process.env.REACT_APP_PF_REGULAR_GASOLINE) || 0,
    ethanol: Number(process.env.REACT_APP_PF_ETHANOL) || 0,
    diesel: Number(process.env.REACT_APP_PF_DIESEL) || 0,
    gnv: Number(process.env.REACT_APP_PF_GNV) || 0,
  },
  publicTransport: {
    metro: Number(process.env.REACT_APP_PF_METRO) || 0,
    subway: Number(process.env.REACT_APP_PF_SUBWAY) || 0,
    bus: Number(process.env.REACT_APP_PF_BUS) || 0,
    van: Number(process.env.REACT_APP_PF_VAN) || 0,
    boat: Number(process.env.REACT_APP_PF_BOAT) || 0,
  },
  airplaneTrip: {
    national: Number(process.env.REACT_APP_PF_AIRPLANE_TRIP_NATIONAL) || 0,
    america: Number(process.env.REACT_APP_PF_AIRPLANE_TRIP_AMERICA) || 0,
    europe: Number(process.env.REACT_APP_PF_AIRPLANE_TRIP_EUROPE) || 0,
    asiaOceania:
      Number(process.env.REACT_APP_PF_AIRPLANE_TRIP_ASIA_OCEANIA) || 0,
    africa: Number(process.env.REACT_APP_PF_AIRPLANE_TRIP_AFRICA) || 0,
  },
  purchasedItems: {
    coats: Number(process.env.REACT_APP_PF_ITEMS_COATS) || 0,
    socks: Number(process.env.REACT_APP_PF_ITEMS_SOCKS) || 0,
    pajamas: Number(process.env.REACT_APP_PF_ITEMS_PAJAMAS) || 0,
    tShirts: Number(process.env.REACT_APP_PF_ITEMS_TSHIRT) || 0,
    tablets: Number(process.env.REACT_APP_PF_ITEMS_TABLETS) || 0,
    notebooks: Number(process.env.REACT_APP_PF_ITEMS_NOTEBOOKS) || 0,
    smartphones: Number(process.env.REACT_APP_PF_ITEMS_SMARTPHONES) || 0,
    computer: Number(process.env.REACT_APP_PF_ITEMS_COMPUTES) || 0,
  },
  food: {
    beef: Number(process.env.REACT_APP_PF_ITEMS_BEEF) || 0,
    chicken: Number(process.env.REACT_APP_PF_ITEMS_CHICKEN) || 0,
    pork: Number(process.env.REACT_APP_PF_ITEMS_PORK) || 0,
    milk: Number(process.env.REACT_APP_PF_ITEMS_MILK) || 0,
    eggs: Number(process.env.REACT_APP_PF_ITEMS_EGGS) || 0,
    riceBean: Number(process.env.REACT_APP_PF_ITEMS_RICE_BEAN) || 0,
  },
  waste: Number(process.env.REACT_APP_PF_WASTE) || 0,
  gas: {
    glp: Number(process.env.REACT_APP_PF_GAS_GLP) || 0,
    piped: Number(process.env.REACT_APP_PF_GAS_PIPED) || 0,
    nothing: Number(process.env.REACT_APP_PF_GAS_NOTHING) || 0,
    status: Number(process.env.REACT_APP_PF_GAS_NOTHING) || 0,
  },
  eletricity: Number(process.env.REACT_APP_PF_ELETRICITY) || 0,
};

export const companyFactors = {
  fuel: {
    regularGasoline:  Number(process.env.REACT_APP_COMPANY_REGULAR_GASOLINE) || 0,
    ethanol: Number(process.env.REACT_APP_COMPANY_ETHANOL) || 0,
    diesel: Number(process.env.REACT_APP_COMPANY_DIESEL) || 0,
    gnv: Number(process.env.REACT_APP_COMPANY_GNV) || 0,
  },
  spendingTransport: Number(process.env.REACT_APP_COMPANY_SPENDING_TRANSPORT) || 0,
  airplaneTrip: {
    national: Number(process.env.REACT_APP_COMPANY_AIRPLANE_TRIP_NATIONAL) || 0,
    international: Number(process.env.REACT_APP_COMPANY_AIRPLANE_TRIP_INTERNATIONAL) || 0,
  },
  eletricity: Number(process.env.REACT_APP_COMPANY_ELETRICITY) || 0,
  gas: Number(process.env.REACT_APP_COMPANY_GAS) || 0,
  airCondicionaire: {
    oneRefill: Number(process.env.REACT_APP_COMPANY_AIR_CONDICIONAIRE_ONE_REFILL) || 0,
    threeRefill: Number(process.env.REACT_APP_COMPANY_AIR_CONDICIONAIRE_THREE_REFILL) || 0,
  },
  powerGenerator: Number(process.env.REACT_APP_COMPANY_POWER_GENERATOR) || 0,
  kwhSourceRenovable: Number(process.env.REACT_APP_COMPANY_KWH_SOURCE_RENOVABLE) || 0,
  consumptionAndWasteGeneratorEmission: Number(process.env.REACT_APP_COMPANY_WASTE) || 0,
};

export const resultFactor = {
  tree: 140,
  flightHours: 21.73913043,
  kgMeet: 6.574621959,
  kmDriven: 6149.711846
}