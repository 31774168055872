import { useState } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  Switch,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";

import Image from "../../../../../assets/img/PJ14.png";
import COChart from "../../COChart";

const UseRenewebleSourceStep = () => {
  const {  showRenewebleSourceEnergyStep, setShowRenewebleSourceEnergyStep, changeStepScreen } =
    useCompanyContext();

  const [useRenewableSource, setRenewebleSource] = useState(false);

  const handleClickNext = (): void => {
    changeStepScreen(showRenewebleSourceEnergyStep ? 1 : 2);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>SUA EMPRESA UTILIZA ENERGIA SOLAR OU EÓLICA?</StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <div className="w-full"> </div>
          <LabelContainer>
            <span>
              Selecione
            </span>
          </LabelContainer>

          <div className="w-full flex justify-center gap-8">
            <span className="text-xl">Não</span>
            <Switch
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => setShowRenewebleSourceEnergyStep(checked)}
            />
            <span className="text-xl">Sim</span>
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default UseRenewebleSourceStep;
