import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Text,
  // Label,
} from "recharts";
import { returnColor } from "../../utils/enums/return-colors";
import { Container, Counter, Label, TextContainer } from "./styles";

export interface IKey {
  key : 'mobility' | 'energy' | 'gas' | 'consumption' | 'waste' | 'home' | 'food' | 'test' | 'publicMobility',
}

export interface Idata extends IKey {
  name: string;
  value: number;
}

export interface CarbonEmissionChartProps {
  data?: Idata[];
  counter?: number;
}

const icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" x={200} y={210}>
    {Array(5)
      .fill("")
      .map((_, i: any) => (
        <g key={i} fill="red">
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </g>
      ))}
  </svg>
);

const propIcon = {
  color: "#ffffff",
  size: 15,
};

const COLORS = [
  "#e7900e",
  "#8438ff",
  "#0dc50d",
  "#ec72f0",
  "#626262",
  "#d40707",
];
const RADIAN = Math.PI / 180;

const CarbonEmissionChart = ({ data, counter }: CarbonEmissionChartProps) => {
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const entities = data;

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      ></text>
    );
  };

  return (
    <Container>
      <PieChart width={100} height={100}>
        <Pie
          data={data}
          dataKey="value"
          fill="#8e8d96"
          innerRadius={20}
          outerRadius={30}
          label={renderCustomizedLabel}
          labelLine={false}
        >
          {data &&
            data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={returnColor({key: data[index].key})}
              />
            ))}
        </Pie>
      </PieChart>

      <TextContainer>
        <Counter>{counter ? counter.toFixed(2) : "0,00"}</Counter>
        <Label>
          t CO<sub>2</sub>e{" "}
        </Label>
      </TextContainer>
    </Container>
  );
};
export default CarbonEmissionChart;
