import * as React from "react";
import { useState } from "react";
import Slider from "../Slider";
import { SliderHeader, SliderBody, SliderFooter } from "./styles";

export interface ISliderWithCounter {
  value?: number|number[]
  defaultValue?: number | number[];
  showCounter?: boolean;
  showFooter?: boolean;
  counterLabel?: string | React.ReactNode | number;
  onChange?(param: number | number[]): void;
  size?: "small" | "medium";
  min?: number;
  max?: number;
  spacingX?: boolean;
  disabled?: boolean
}

const SliderWithCounter = ({
  value,
  defaultValue,
  showCounter,
  showFooter,
  counterLabel,
  onChange,
  size,
  min,
  max,
  spacingX,
  disabled,
}: ISliderWithCounter) => {
  const [counterValue, setCounterValue] = useState<number | number[]>(0);

  const options = {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  };
  const formatNumber = new Intl.NumberFormat("pt-BR", options);

  const handleChange = (value: number | number[]) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <React.Fragment>
      {showCounter && (
        <SliderHeader size={size}>
          {counterLabel ? counterLabel : counterValue}
        </SliderHeader>
      )}
      <SliderBody spacingX={spacingX}>
        <Slider
          aria-label="Default"
          valueLabelDisplay="auto"
          defaultValue={defaultValue ? defaultValue : 0}
          onChange={(event: Event, newValue: number | number[]) => {
            handleChange(newValue);
            setCounterValue(newValue);
          }}
          value={value}
          {...{ size, min, max, disabled }}
        />
      </SliderBody>
      {showFooter && (
        <SliderFooter size={size}>
          <span>{formatNumber.format(min ? min : 0)}</span>
          <span>{formatNumber.format(max ? max : 0)}</span>
        </SliderFooter>
      )}
    </React.Fragment>
  );
};
export default SliderWithCounter;
