import styled from "styled-components";

export const IconSwitch = styled.img`

  width: 35px;
  height: 35px;
  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    
  }
`
export default IconSwitch