import * as React from 'react';
import styled from "styled-components";
import theme from "../../config/theme/light"

interface ITitle extends React.PropsWithChildren{
  color?: string
}

const Title = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  color: ${({theme, color}) => color || theme.colors.black};
  font-size: 1rem;
  line-height: 1.3rem;
`
Title.defaultProps = {theme:theme}

const StepTitle: React.FC<ITitle> = ({ children }) => {
  return (
    <Title role="presentation">{children}</Title>
  )
}
export default StepTitle