import * as React from "react";
import styled from "styled-components";
import SliderWithCounter from "../SliderWithCounter";
import InputNumber, { InputNumberProps } from "../InputNumber";

export const Field = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 25px;

  & > div {
    width: 50%;
  }

  & .label-fuel-step {
    font-size: 0.9rem;
  }
`;

interface ISliderField extends InputNumberProps {
  label: string | React.ReactNode;
  alignLabel?: "left" | "right";
  value?: number | number[]
  defaultValue?: number | number[];
  showCounter?: boolean;
  showFooter?: boolean;
  isDigitable?: boolean;
  counterLabel?: string | React.ReactNode | number;
  onChange?(param: number | number[]): void;
  min?: number;
  max?: number;
  disabled?: boolean
}

const SliderField = ({
  label,
  alignLabel,
  defaultValue,
  value,
  showCounter,
  showFooter,
  isDigitable,
  counterLabel,
  onChange,
  min,
  max,
  suffix,
  inputIconAlign,
  placeholder,
  mask,
  disabled,
}: ISliderField) => {
  const handleChange = (value: number | number[]) => {
    if (onChange) {
      onChange(value);
    }
  };

  const handleChangeInputNumber = (value: number) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <React.Fragment>
      <Field>
        <div style={{ textAlign: alignLabel ? alignLabel : "left" }}>
          <span className="label-fuel-step">{label}</span>
        </div>
        {isDigitable ? (
          <InputNumber
            {...{ min, max, placeholder, inputIconAlign }}
            mask={mask}
            suffix={suffix}
            onChange={handleChangeInputNumber}
          />
        ) : (
          <div>
            <SliderWithCounter
              size="small"
              defaultValue={defaultValue ? defaultValue : 0}
              onChange={(newValue: number | number[]) => handleChange(newValue)}
              spacingX={true}
              value={value}
              {...{ min, max, showCounter, showFooter, counterLabel, disabled}}
            />
          </div>
        )}
      </Field>
    </React.Fragment>
  );
};

export default SliderField;
