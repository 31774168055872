import * as React from 'react';
import styled from 'styled-components';
import Switch from "../Switch";
import theme from "../../config/theme/light"

interface SwitchFieldType {
  label?: string | React.ReactNode,
  checked?: boolean
  onChange?(value: boolean): void
}

export const Field = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  column-gap: ${({ theme }) => theme.matrics.padding}px;
  /* max-width: 160px; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.matrics.padding / 2}px;

  @media (min-width: ${({ theme }) => theme.matrics.sm}px) {
    margin-bottom: 10px;
  }
`
Field.defaultProps = {theme:theme}


const SwitchField = ({ label, checked, onChange }: SwitchFieldType) => {
  const handleChangeSwitch = (checked: boolean) => {
    if (onChange) {
      onChange(checked)
    }
  }

  return (
    <Field>
      <span>{label}</span>
      <Switch
        checked={checked}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChangeSwitch(checked)}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </Field>
  )
}
export default SwitchField