import * as React from 'react';
import {Idata} from "../../CarbonEmissionChart"
import {Icon} from "../styles"
import AirPlane from "../../../assets/icons/airplane.png";
import { CarbonEmissionChartProps } from "../../CarbonEmissionChart";
import {returnIcon} from "../CarbonEmissionChart/return-icons"

const CarbonEmissionInformation = ({data, counter}: CarbonEmissionChartProps) => {
  const calculatePercentage = (entity:number) => {
    let value = counter && Math.floor( (entity * 100) / counter)
    return value
  }

  return (
    <div className="w-full mt-10 md:mt-0 flex flex-col gap-y-5">
      {data?.map(d => (
         <div className="flex gap-5 m-auto w-full justify-between text-right">
         <div>
          {returnIcon({key:d.key, x:0, y:0})}
         </div>
         <div>
           <p className='text-center'>{d.name}</p>
         </div>
         <div className=''>
           <p className='font-semibold text-right'>{calculatePercentage(d.value)}%</p>
         </div>
       </div>
      ))}
    </div>
  )
}
export default CarbonEmissionInformation