import {
  BackButton,
  StepTitle,
  Button,
  Counter,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { FcLandscape } from "react-icons/fc";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";
import BackgroundImage from "../../../../../assets/img/PF07.png";

const PersonQuantityStep = () => {
  const { setPeopleQuantity, changeStepScreen } = usePersonContext();

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container backgroundColor="#E7ECFF">
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              Quantas pessoas moram em sua residência?
            </StepTitle>
          </div>
          <div>
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <div className="w-full"> </div>
          <LabelContainer>
            <span>Selecione a quantidade incluindo você</span>
          </LabelContainer>

          <div className="w-8/12">
            <Counter
              defaultValue={1}
              onChange={(value: number) => setPeopleQuantity(value)}
              min={1}
              max={20}
            />
          </div>

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default PersonQuantityStep;
