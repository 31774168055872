import { FormContent } from "./components"

import { CompanyProvider } from "./context/CompanyContext"

const CompanyCalculator = () => {
  return (
    <CompanyProvider>
      <FormContent />
    </CompanyProvider>
  )
}
export default CompanyCalculator