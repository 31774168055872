import * as React from "react";
import { useCompanyContext } from "../../context/CompanyContext";
import {
  CompanyNameStep,
  CompanySegmentStep,
  YearStep,
  StateStep,
  EmployeeInformationStep,
  FuelStep,
  SpendingTransportAppsStep,
  TimeSpentStep,
  AirTravelStep,
  EnergyStep,
  CookingGasStep,
  AirConditioningStep,
  PowerGeneratorStep,
  UseRenewebleSourceStep,
  RenewebleSourceEnergyStep,
  DiscartedWastStep,
  RecyclingPercetageStep,
} from "../steps";
import { ResultPageModel } from "../../../../components"

const FormContent = () => {
  const { stepScreen, emissionCarbon, dataCarbonEmission, changeStepScreen } = useCompanyContext();

  // console.log("teste", emissionCarbon, dataCarbonEmission)

  // List of steps to render each per number of 'stepScreen'
  const steps = [
    <CompanyNameStep />,
    <CompanySegmentStep />,
    <YearStep />,
    <StateStep />,
    <EmployeeInformationStep />,
    <FuelStep />,
    <SpendingTransportAppsStep />,
    <TimeSpentStep />,
    <AirTravelStep />,
    <EnergyStep />,
    <CookingGasStep />,
    <AirConditioningStep />,
    <PowerGeneratorStep />,
    <UseRenewebleSourceStep />,
    <RenewebleSourceEnergyStep />,
    <DiscartedWastStep />,
    <RecyclingPercetageStep />,
    <ResultPageModel
      emissionCarbon={emissionCarbon}
      data={dataCarbonEmission}
      onBack={() => changeStepScreen(-1)}
    />,
  ];

  return (
    <React.Fragment>
      {steps.map((component, index) => (
        <div style={{ display: stepScreen === index ? "block" : "none" }}>
          {component}
        </div>
      ))}
    </React.Fragment>
  );
};
export default FormContent;
