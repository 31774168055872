import { createTheme } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';

let theme = createTheme({
  palette: {
    primary: {
      main: "#f5f5f5",
      light: "#ffffff",
      dark: '#ffffff',
      contrastText: '#ffffff',
    },
    secondary: {
      main: "#5f25ff",
      light: "#2c00a3",
      dark: "#8c65f7",
      contrastText: '#ffffff',
    },

  },
});

export default theme