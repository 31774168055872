import styled from "styled-components";

export const Field = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${({theme})=> theme.matrics.padding}px;

  & > div:nth-child(1) {
    width: 60%;
  }
  & > div:nth-child(2) {
    width: 40%;
  }

  & .label-step {
    font-size: .8rem;
  }
`;