import styled from "styled-components";
import { Select } from "@mui/material";

const SelectCustomized = styled(Select)((props) => ({
  // justifyContent: "center",
  margin: "0px",
  "& .MuiSelect-select": {
    borderBottom: "3px solid #fff",
    // justifyContent: "center",
    color: "#fff",
    // textAlign: "right",
    fontSize: "1.5rem",
    textIdent: "25px",
    textAlign: "center",
  },
  "& .MuiSelect-standard": {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiSelect-icon": {
    color: "#ffffff",
  },
}));

export default SelectCustomized;
