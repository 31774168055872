import styled from "styled-components";

const SliderContainer = styled.div`
 display: flex;
 align-items: center;

 & > span.icon-left {
   margin-right: 25px
 }

 & > span.icon-right {
   margin-left: 25px;
 }
`

export default SliderContainer