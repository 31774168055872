import styled from 'styled-components'
import theme from "../../config/theme/light";

export const Image = styled.img`
  max-width: 60px;
`
Image.defaultProps = {
  theme:theme
}

export const Label = styled.div`
  text-align: center;
  font-size: 1rem;
  color: ${({theme}) => theme.colors.text};
  width: 100%;
  margin-top: ${({theme}) => theme.matrics.padding / 2}px;
  margin-bottom: ${({theme}) => theme.matrics.padding / 2}px;
`



export const CustomLabelCounter = styled.div`
  font-size: 1.8rem
`