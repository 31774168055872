import {
  BackButton,
  StepTitle,
  Button,
  FieldWithLabelAndIcon,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { useCompanyContext } from "../../../context/CompanyContext";
import { IoArrowForwardSharp } from "react-icons/io5";

import Image from "../../../../../assets/img/PJ1516.png";
import Icon from "../../../../../assets/icons/PJ15.png";
import { CustomLabelCounter } from "../../../../../components/FieldWithLabelAndIcon/styles";
import COChart from "../../COChart";

const DiscartedWastStep = () => {
  const { showRenewebleSourceEnergyStep , changeStepScreen, setWasteKg } = useCompanyContext();

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(showRenewebleSourceEnergyStep ? -1 : -2)} />
            <StepTitle>
            EM MÉDIA, QUANTOS QUILOS DE RESÍDUOS SÃO DESCARTADOS POR MÊS NA SUA EMPRESA?
            </StepTitle>
          </div>
          <div className="w- full flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={Image} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <div className="w-full"> </div>

          <FieldWithLabelAndIcon
            icon={Icon}
            label="Digite a média mensal"
            inputType="inputNumber"
            placeholder="0"
            suffix={<CustomLabelCounter>Kg</CustomLabelCounter>}
            inputIconAlign="right"
            onChange={(value:number|number[]) => setWasteKg(value)}
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default DiscartedWastStep;
