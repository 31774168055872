import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SliderWithIcon,
  IconSliderWithIcon,
  Switch,
  FieldSwitcher,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";
import { IoArrowForwardSharp, IoFastFoodSharp } from "react-icons/io5";
import COChart from "../../COChart";
import BackgroundImage from "../../../../../assets/img/PF13.png";
import CutleryIcon from "../../../../../assets/icons/PF13.png";

const CalorieConsumption = () => {
  const { loading, changeStepScreen } = usePersonContext();
  const [amountSpend, setAmountSpend] = useState<number | number[]>(0);
  const [isDigitable, setIsDigitable] = useState(false);

  const handleClickNext = (): void => {
    changeStepScreen(1);
  };

  useEffect(() => {
    setAmountSpend(0);
  }, [isDigitable]);

  return (
    <Container>
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>Quanto você diria que come por dia?</StepTitle>
          </div>
          <div>
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Em média. quanto você gasta com sua alimentação</span>
          </LabelContainer>

          <div className="w-10/12">
            <SliderWithIcon
              showFooter
              min={0}
              max={500}
              placeholder="0,00"
              suffix="R$"
              mask="currency"
              icon={<IconSliderWithIcon src={CutleryIcon} />}
              isDigitable={isDigitable}
              onChange={(newValue: number | number[]) =>
                setAmountSpend(newValue)
              }
              counterLabel={<span>R$ {amountSpend},00</span>}
            />
          </div>

          <FieldSwitcher
            onChange={(checked: boolean) => setIsDigitable(checked)}
          />

          <ButtonContainer>
            <Button styleType="third" onClick={() => handleClickNext()}>
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default CalorieConsumption;
