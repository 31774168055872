import { useState, useEffect } from "react";
import {
  BackButton,
  StepTitle,
  Button,
  SwitchField,
} from "../../../../../components";
import {
  Container,
  FormResponsible,
  StepHeader,
  ImageContainer,
  StepForm,
  ResponsibleContainer,
  LabelContainer,
  ButtonContainer,
} from "../../../../../components/styles";
import { usePersonContext } from "../../../context/personContext";

import { IoArrowForwardSharp } from "react-icons/io5";
import COChart from "../../COChart";
import BackgroundImage from "../../../../../assets/img/PF09-10.png";

const GasTypesStep = () => {
  const { setShowGasStep, changeStepScreen, setGasTypeSelected } =
    usePersonContext();

  const [firstField, setFirstField] = useState(false);
  const [secondField, setSecondField] = useState(false);
  const [thirdField, setThirdField] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleClickNext = (): void => {
    const showGasStep = thirdField ? false : true;
    setShowGasStep(showGasStep);
    changeStepScreen(showGasStep ? 1 : 2);
  };

  const handleSelectSwitch = (checked: boolean, nth: number) => {
    if (nth === 1) {
      setFirstField(checked);
      setSecondField(false);
      setThirdField(false);
      setGasTypeSelected(checked ? 1 : 0);
    } else if (nth === 2) {
      setFirstField(false);
      setSecondField(checked);
      setThirdField(false);
      setGasTypeSelected(checked ? 2 : 0);
    } else if (nth === 3) {
      setFirstField(false);
      setSecondField(false);
      setThirdField(checked);
      setGasTypeSelected(0);
    }
  };

  useEffect(() => {
    if (firstField || secondField || thirdField) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [firstField, secondField, thirdField]);

  return (
    <Container backgroundColor="#A0D99F">
      <ResponsibleContainer spacingX spacingY>
        <StepHeader>
          <div>
            <BackButton onClick={() => changeStepScreen(-1)} />
            <StepTitle>
              QUAL TIPO DE GÁS VOCÊ UTILIZA EM SUA RESIDÊNCIA?
            </StepTitle>
          </div>
          <div className="flex justify-end">
            <COChart />
          </div>
        </StepHeader>
      </ResponsibleContainer>

      <ImageContainer src={BackgroundImage} />

      <StepForm>
        <FormResponsible spacingX={true} spacingY={true}>
          <LabelContainer>
            <span>Selecione uma opção</span>
          </LabelContainer>

          <div className="w-8/12">
            <SwitchField
              label="Botijão (GLP)"
              checked={firstField}
              onChange={(checked: boolean) => handleSelectSwitch(checked, 1)}
            />
          </div>
          <div className="w-8/12">
            <SwitchField
              label="Gás encanado"
              checked={secondField}
              onChange={(checked: boolean) => handleSelectSwitch(checked, 2)}
            />
          </div>
          <div className="w-8/12">
            <SwitchField
              label="Nenhum"
              checked={thirdField}
              onChange={(checked: boolean) => handleSelectSwitch(checked, 3)}
            />
          </div>

          <ButtonContainer>
            <Button
              styleType="third"
              onClick={() => handleClickNext()}
              disabled={disabled}
            >
              Avançar <IoArrowForwardSharp size={20} />
            </Button>
          </ButtonContainer>
        </FormResponsible>
      </StepForm>
    </Container>
  );
};

export default GasTypesStep;
