import React from "react";
import { Icon } from "../styles";
import { Header, SubTitle } from "../../styles";
import { resultFactor } from "../../../utils/factors";
import { numberFormat } from "../../../utils";

import AirPlane from "../../../assets/icons/airplane.png";
import Florest from "../../../assets/icons/floresta.png";
import Car from "../../../assets/icons/car.png";
import Cow from "../../../assets/icons/cow.png";

interface EmissionEquivalence {
  emissionCarbon?: number;
}

const EmissionEquivalence = ({ emissionCarbon }: EmissionEquivalence) => {
  const formatNumber = (value:number) => {
    return Math.ceil(value)
  }

  return (
    <div className="w-full gap-5 flex flex-col mt-3 mb-3">
      <div className="w-full flex flex-col gap-5">
        <Header>
          <SubTitle>Em um ano, as suas emissões equivalem a:</SubTitle>
        </Header>

        <div className="flex gap-5 m-auto w-9/12 justify-between text-left">
          <div>
            <Icon src={AirPlane} />
          </div>
          <div>
            <p>
              {emissionCarbon &&
                formatNumber(resultFactor.flightHours * emissionCarbon)} voos de São Paulo para Fortaleza
            </p>
          </div>
        </div>
        <div className="flex gap-5 m-auto w-9/12 justify-between text-left">
          <div>
            <Icon src={Florest} />
          </div>
          <div>
            <p>
              Captura de CO2 de{" "}
              {emissionCarbon &&
                Math.round(resultFactor.tree * emissionCarbon)}{" "}
              árvores em 20 anos
            </p>
          </div>
        </div>
        <div className="flex gap-5 m-auto w-9/12 justify-between text-left">
          <div>
            <Icon src={Car} />
          </div>
          <div>
            <p>
              Rodar{" "}
              {emissionCarbon &&
                 Math.round(resultFactor.kmDriven * emissionCarbon)}{" "}
              km de carro a gasolina
            </p>
          </div>
        </div>
        <div className="flex gap-5 m-auto w-9/12 justify-between text-left">
          <div>
            <Icon src={Cow} />
          </div>
          <div>
            <p>
              Consumir{" "}
              {emissionCarbon &&
                 Math.round(resultFactor.kgMeet * emissionCarbon)}{" "}
              kg de carne bovina
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmissionEquivalence;
